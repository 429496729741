import { PORTAL } from '@aex/shared/common-lib';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-check-email',
	templateUrl: './check-email.component.html',
	styleUrls: ['./check-email.component.scss'],
})
export class CheckEmailComponent {
	public readonly title: string = 'Check your email';

	constructor(private readonly router: Router) {
	}

	public backToLogin(): void {
		this.router.navigate([PORTAL.loginPage.name]).then();
	}
}
