<app-auth-wrapper [title]="title">
	<form #loginForm="ngForm" class="k-form k-form-md">
		<fieldset class="k-form-fieldset">
			<kendo-formfield>
				<kendo-label class="field-label" text="Email"></kendo-label>
				<kendo-textbox #emailInput="ngModel" [(ngModel)]="email" [email]="true" [clearButton]="true" class="custom-input" name="email" required></kendo-textbox>
				<div *ngIf="emailInput.invalid && emailInput.touched">
					<kendo-formerror>Please enter a valid email address</kendo-formerror>
				</div>
			</kendo-formfield>
			<kendo-formfield>
				<kendo-label class="field-label" text="Password"></kendo-label>
				<kendo-textbox [(ngModel)]="password" [clearButton]="true" [type]="showPassword ? 'text' : 'password'" class="custom-input" name="password" required></kendo-textbox>
			</kendo-formfield>
			<div class="form-actions">
				<a [routerLink]="forgotPasswordLink" class="form-action">Forgot password?</a>
				<button (click)="togglePassword()" class="form-action">{{ showPassword ? 'Hide password' : 'Show password' }}</button>
			</div>

			<app-alert-bar
				*ngIf="showError"
				[showIcon]="false"
				[text]="errorMessage"
				containerClass="error-message-container"
				data-testid="invalid-credentials-alert"
				textClass="error-message-text"
				type="danger"
			></app-alert-bar>
			<button (click)="login()" [disabled]="loginForm.invalid" class="auth-form-button" kendoButton themeColor="primary">Login</button>
		</fieldset>
	</form>
</app-auth-wrapper>
