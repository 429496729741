import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GridRoutingService {
	private readonly gridViewModalSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly gridViewModalStream: Observable<boolean> = this.gridViewModalSubject.asObservable();

	private readonly intendedUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private readonly shouldNavigateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

	public get intendedUrlState(): string {
		return this.intendedUrlSubject.getValue();
	}

	public set intendedUrlState(data: string) {
		this.intendedUrlSubject.next(data);
	}

	public get shouldNavigateState(): boolean {
		return this.shouldNavigateSubject.getValue();
	}

	public set shouldNavigateState(data: boolean) {
		this.shouldNavigateSubject.next(data);
	}

	public set gridViewModalState(data: boolean) {
		this.gridViewModalSubject.next(data);
	}
}
