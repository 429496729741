import { isTokenExpired } from '@aex/ngx-toolbox';
import { AuthType, getMetaData, HEADER_AUTH, Utils } from '@aex/shared/common-lib';
import { AuthService } from '@aex/shared/root-services';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PortalAuthInterceptor implements HttpInterceptor {
	constructor(private readonly authService: AuthService) {
	}

	private _authToken: string;

	public get authToken(): string {
		if (!this.authService.authToken) return null;
		const token = Utils.bearer(this.authService.authToken);
		this._authToken = token && !isTokenExpired(token) ? token: null;
		return this._authToken;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const setHeaders: { [name: string]: string } = {
			'x-proxy-request': 'frontend',
		};

		if(this.authService.operatorId.length > 0)
			setHeaders['x-operator-id'] = this.authService.operatorId;

		const meta = getMetaData(req.params);
		const authType = Utils.authTypeFromMeta(meta);

		if (
			authType !== AuthType.NONE &&
			!req.headers.has(HEADER_AUTH) &&
			this.authToken
		)
			setHeaders[HEADER_AUTH] = this.authToken;

		return next.handle(req.clone({ setHeaders, reportProgress: true })).pipe(
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			}),
		);
	}
}


