import { Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { GridModalService } from '../../../services/grid-modal.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-save-view-dialog',
	templateUrl: './save-view-dialog.component.html',
})
export class SaveViewDialogComponent extends DialogContentBase {
	public canEdit: Observable<boolean> = this.gridModalService.canEditStream;
	public canSave: Observable<boolean> = this.gridModalService.canSaveStream;
	public editSaveStateStream: Observable<{ canEdit: boolean; canSave: boolean }> = combineLatest([
		this.canEdit,
		this.canSave,
	]).pipe(
		map(([canEdit, canSave]) => ({ canEdit, canSave })),
	);
	constructor(
		private readonly gridModalService: GridModalService,
		public dialog: DialogRef,
	) {
		super(dialog);
	}

	public handleAction(action: string): void {
		this.gridModalService.getBaseDataGridRef().onViewModalAction(action);
		this.dialog.close();
	}
}
