import { IClickableColumn, IFilterQuery, IGridColumn, IPagination, IRecord, ISortQuery, ITaskRecord, SORT_ORDER } from '@aex/shared/common-lib';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ColumnReorderEvent, GridDataResult, ModifierKey, MultipleSortSettings, PageChangeEvent, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { isEqual } from 'lodash';
import { CHIP_STATUS_FIELDS, PAGE_SIZE_LEVEL_1, PAGE_SIZE_LEVEL_2, PAGE_SIZE_LEVEL_3, PAGE_SIZE_LEVEL_4, SERVICE_CHIP_COLOR_STATUS } from '../../../shared/constant';
import { getKeyPathFromColumnObject } from '../../../util/common.utils';
import { IGridColumnToDisplay } from './data-grid.types';
import { ConfigService } from '@aex/shared/root-services';

@Component({
	selector: 'app-data-grid',
	templateUrl: './data-grid.component.html',
})
export class DataGridComponent implements OnChanges {
	@Input() public data: IRecord[];
	@Input() public totalCount: number;

	@Input() public selectedGridColumns?: IGridColumn[];
	@Input() public pageSize?: number;
	@Input() public page?: number;
	@Input() public sort?: SortDescriptor[];
	@Input() public clickableColumns?: IClickableColumn[];
	@Input() public highlightedHeading?: string;

	@Output() public readonly handlePagination = new EventEmitter<IPagination>();
	@Output() public readonly handleSort = new EventEmitter<ISortQuery>();
	@Output() public readonly handleFilter = new EventEmitter<IFilterQuery>();
	@Output() public readonly handleGridStateChange = new EventEmitter<{ columns: IGridColumnToDisplay[]; selectedColumns: IGridColumn[] }>();
	@Output() public readonly handleCellClick = new EventEmitter<ITaskRecord>();

	public gridColumns: IGridColumnToDisplay[] = [];
	public type: PagerType = 'numeric';
	public pagesCountToPaginate = 5;
	public info = true;
	public pageSizes = true;
	public previousNext = true;
	public position: PagerPosition = 'bottom';

	public skip = (this.page - 1) * this.pageSize;

	public modifierKey: ModifierKey = 'none';
	public chipStatusFields: string[] = CHIP_STATUS_FIELDS;
	public sortSettings: MultipleSortSettings = {
		mode: 'multiple',
		multiSortKey: this.modifierKey,
		initialDirection: 'desc',
		allowUnsort: false,
	};

	public readonly sizes: number[] = [PAGE_SIZE_LEVEL_1, PAGE_SIZE_LEVEL_2, PAGE_SIZE_LEVEL_3, PAGE_SIZE_LEVEL_4];
	public columnWidth: number = 180;

	public sort_field: string;
	public sort_order: SORT_ORDER;

	public readonly chipStatusMapping = SERVICE_CHIP_COLOR_STATUS;
	public readonly dateColumnType:string = 'DateTimeOffset';
	public dateFormat: string;

	constructor(private readonly configService: ConfigService){
		this.dateFormat = this.configService.operatorDateFormat;
	}

	public get gridView(): GridDataResult {
		return {
			data: this.data,
			total: this.totalCount,
		};
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (!this.data?.length)
			return;

		if (Array.isArray(changes['selectedGridColumns']?.currentValue) && !isEqual(this.gridColumns, this.selectedGridColumns))
			this.gridColumns = this.selectedGridColumns
				.map((column) => {
					return {
						heading: column.heading,
						name: getKeyPathFromColumnObject(column),
						statusField: this.isStatusField(`${column.field}.${column.sub_field}`),
						dateTimeColumn: column.type.includes(this.dateColumnType),
					};
				})
				.filter(Boolean);
	}

	private isStatusField(fieldName: string): boolean {
		return this.chipStatusFields.includes(fieldName);
	}


	public pageChange({ skip, take }: PageChangeEvent): void {
		this.skip = skip;
		this.pageSize = take;
		let page = skip / take + 1;
		this.handlePagination.emit({
			count: take,
			page: page,
		});
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;

		const sortDescriptor = sort[sort.length - 1];
		const column = this.selectedGridColumns.find((column) => sortDescriptor.field === `${column['field']}.${column['sub_field']}`);

		if (!column)
			return;

		if (sortDescriptor.dir) {
			this.sort_field = ['String', 'Guid'].includes(column.type) ? `${column.field}.${column.sub_field}.keyword` : `${column.field}.${column.sub_field}`;
			this.sort_order = sortDescriptor.dir as SORT_ORDER;

			this.handleSort.emit({
				sort_field: this.sort_field,
				sort_order: this.sort_order,
			});
		}
	}

	public goToDetail(value: ITaskRecord): void {
		this.handleCellClick.emit(value);
	}

	public onColumnReorder(event: ColumnReorderEvent): void {
		const { newIndex, oldIndex } = event;

		const columns = [...this.gridColumns];
		const selectedColumns = [...this.selectedGridColumns];
		columns.splice(newIndex, 0, columns.splice(oldIndex, 1)[0]);
		selectedColumns.splice(newIndex, 0, selectedColumns.splice(oldIndex, 1)[0]);

		this.gridColumns = columns;
		this.selectedGridColumns = selectedColumns;

		this.handleGridStateChange.emit({
			columns,
			selectedColumns,
		});
	}
}
