import { CommonLibModule } from '@aex/shared/common-lib';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
	declarations: [
		// ClassicPortalContainerComponent,
	],
	imports: [
		CommonModule,
		// TODO reinclude when production urls have been updated
		// RouterModule.forChild(EMBEDDED_PORTAL_ROUTES),
		CommonLibModule,
	],
	exports: [],
})
export class ClassicPortalModule {}
