import { IDateRange} from './interfaces';
import { CalendarEvent, EventAction, EventColor } from 'calendar-utils';


export enum InstallStatusFilter {
  IN_PROGRESS = 'In Progress',
  OVERDUE = 'Overdue',
  AWAITING = 'Awaiting Config',
  PENDING = 'Pending',
}

export enum InstallType {
  ALL = 0,
  INSTALL = 1,
  FAULT = 512,
  REPAIR = 1024,
  PREORDER = 4096,
  RELOCATION = 16384,
  PREMIUM_SUPPORT_FAULT = 32769,
  PREMIUM_SUPPORT_REPAIR = 65537,
}

export interface IInstallFilter extends IDateRange {
  installType?: InstallType;
  dateFilterType: DateFilterType;
}

export enum DateFilterType {
  ALL, CURRENT_WEEK, CUSTOM, TODAY,
}

export interface IInstallBase {
  serial_number?: string;
  date?: string;
  label?: string;
  destination_status_id?: string;
  comments?: string;
  assigned_user?: string;
  colors?: EventColor;
  modified_by_id?: string;
  scheduled_date_time?: Date;
  created_by?: string;
  peId?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  business_name?: string;
  mobileNumber?: string;
  identityNumber?: string;
  type_id?: number;
  type?: string;
  status_id?: string;
  status?: string;
  account_id?: string;
  reference?: string;
  on_hold?: boolean;
  completed_date?: string;
  schedule_date?: string;
  schedule_time?: string;
  close_date?: string;
  closed?: boolean,
  category?: string;
  assigned_id?: string;
  assigned_to_name?: string;
  assigned_user_id?: string;
  assigned_user_name?: string;
  assigned?: string;
  installer_id?: string;
  installer_name?: string;
  last_updated_days?: string;
  days_open?: string;
  service_id?: string;
  comment?: string;
  summary?: string;
  description?: string;
  network_parent_product_name?: string;
  full_address?: string;
  mdu_number?: string;
  mdu_number_int?: number;
  mdu_name?: string;
  street_number?: string;
  street_name?: string;
  intersection?: string;
  suburb?: string;
  city?: string;
  postal_code?: string;
  province?: string;
  country_code?: string;
  asset_reference?: string;
  latitude?: number;
  longitude?: number;
  lat?: number;
  lon?: number;
  speed_up?: string;
  speed_down?: string;
  area_type?: string;
  network_type?: string;
  area_type_name?: string;
  facility_id?: string;
  id?: number | string,
  text?: string;
  name?: string;
  icon?: ColourGroups;
  colours?: ColourGroups;
  updated_at?: string;
  created_at?: string;
  customer_id?: string;
  area_id?: string;
  premise_id?: string;
  isp_product_id?: string;
  network_product_id?: string;
  backhaul_product_id?: string;
  operator_id?: string;
  provider_id?: string;
  preorder?: boolean;
  isp_reference?: string;
  port_number?: number;
  pppoe_username?: string;
  pppoe_ip_address?: string;
  wifi_username?: string;
  provisioned_date?: Date;
  on_network_date?: string;
  cancelled_date?: string;
  expiry_date?: string;
  promo_code?: string;
  promotion_ids?: string;
  auto_configured?: string;
  created_by_id?: string;
  created_date?: Date;
  created_by_user?: string;
  address?: string;
  distance?: number;
  last_comment?: string;
  map_url?: string;
  mobile_number?: string;
  navigation_address?: string;
  status_class?: string;
  extraInfo?: IExtraInstallInfo;
  external_reference?: string;
  page?: number;
  key?: string;
  value?: string;
  count?: number;
  email?: string;
}

export interface IInstall extends IInstallBase {
  workorder?: IInstallBase;
  workOrder?: IInstallBase;
  customer?: IInstallBase;
  provider?: IInstallBase;
  premise?: IInstallBase;
  point?: IInstallBase,
  ispProduct?:IInstallBase;
  isp_product?: IInstallBase,
  network_product?: IInstallBase,
  area?: IInstallBase;
  parent_area?: IInstallBase;
  complex?: IInstallBase;
  region?: IInstallBase;
  parent_region?: IInstallBase;
  service?: IInstallBase;
  asset_attributes?: IInstallBase;

}

export interface WorkOrderService{
  items: IInstall[];
  count?: number;
  page?: number;
}


export interface IExtraInstallInfo {
  serialNumber: string;
  ispReference: string;
  validSerialNumbers: string[];
  inValidSerialNumbers: string[];
  requiredImages: string[];
  customerId: string;
  providerName?: string;
  premiseId?: string;
  serviceLevel?: number;
}

export class Company {
  public assigned: string;
  public ['assigned_id']: string;
  public people: IInstallBase[];
}

export class FilterOptions {
  public type: CalendarFilter;
  public team: string;
}

export enum CalendarFilterByEvents {
  'Install' = 'Install',
  'Repairs' = 'Repairs',
  'All' = 'All',
}

export enum CalendarViewEvents {
  Month = 'month',
  Week = 'week',
  Day = 'day'
}

export enum CalendarFilter {
  ALL = 0,
  INSTALL = 1,
  REPAIR = 1024,
}

export interface IOptions {
  beforeStart?: boolean;
  afterEnd?: boolean;
  tmpEvent?: boolean;
}
export interface DialogData{
  workOrderId?: string;
  form: ICreateOrUpdateWorkOrder;
  calendarItem: ISchedulerWorkOrder;
}

export interface SaveAndSetCalendarEvent {
  data: IInstall;
  workOrder: DialogData;
}
export interface ICreateOrUpdateWorkOrder {
  selectedPerson: string;
  selectedCompany: string;
  comments: string;
  scheduleDate: string;
  scheduleTime: string;
  statusId: string;
  workOrder: string;
}

export interface Customer{
  email: string;
  name: string;
  address: string;
  number: string;
  mapUrl: string;
}
export interface DetailIds{
  workOrderId: string | number;
  typeId: number;
  companyId: string;
  userId: string;
  user: string;
  statusId: string;
  status: string;
  company: string;
}
export interface EventItem{
  subTitle: string;
  reference: string;
  dateRange: string;
  company: string;
  colour: EventColor;
  end: Date;
  actions?: EventAction[];
  style: string;
  group: string;
  displayTitle: string;
}
export interface Isp{
  name: string;
  product: string;
  premise: string;
  id: string|number;
}
export interface ISchedulerWorkOrder extends CalendarEvent{
  customer?: Customer;
  date?: string;
  detail?: DetailIds;
  event?: EventItem;
  isp?:Isp;
  provider?: Provider;
}
export interface Provider{
  name: string;
  icon: string;
  id: string;
}

export interface IUnScheduledWorkOrder {
  text: string;
  id: string;
  data: ISchedulerWorkOrder;
}
export interface Teams {
  key: string;
  value: string;
  colours: ColourGroups;
  style: string;
}

export enum InstallLoadType{
  Initial = 0,
  Manual = 1,
}
export interface Status{
  PENDING: string;
  SCHEDULED: string;
  NEW_REPAIR: string;
  REPAIR_IN_PROGRESS: string;
  REPAIR_PENDING: string;
  TESTING: string;
}
export interface ColourGroups{
  primary: string;
  secondary: string;
}


export interface SchedulerOrder {
  unScheduledWorkOrders: ISchedulerWorkOrder[];
  calenderEventsScheduled: ISchedulerWorkOrder[];
  teams?: Teams[];
  originalData?:IInstall[];
  statuses?: IInstallBase[];
  calendarOriginalEvents?: ISchedulerWorkOrder[];
  statusesToGo?: IInstallBase[];
  types?: IInstallBase[];
  installers?: IInstallBase[];
  providers?: IInstallBase[];
  statusesWithNoFilter?: IInstallBase[];
}

export interface InitialScheduler {
  types: IInstallBase[];
  statuses: IInstallBase[];
  providers: IInstallBase[];
}

export enum FilterTypeIds {
  Installations = 1,
  Repairs = 1024,
  PreOrder = 4096,
}