import { ENVIRONMENT_CONFIG_TOKEN, IEnvironment } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { capitalize, isNil } from 'lodash';
import { zip } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	template: `
		<link rel="stylesheet" [href]="cssUrl">
		<ngx-ui-loader></ngx-ui-loader>
		<router-outlet></router-outlet>
	`,
})
export class AppComponent {
	public title = 'portal';
	public favIcon: HTMLLinkElement = document.querySelector('#appIcon');
	public cssUrl: SafeResourceUrl;
	constructor(
		private readonly titleService: Title,
		private readonly configService: ConfigService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) private readonly environment: IEnvironment,
		public sanitizer: DomSanitizer,
	) {
		zip(
			this.configService.configValuesStream,
			this.configService.operatorStream,
		).pipe(
			filter(([x, y]) => !!x && !!y)).subscribe(([configValues, operator]) => {
			const theme = this.environment?.override?.theme ?? operator;
			if (!isNil(this.favIcon) && !isNil(theme))
				this.favIcon.href = `assets/img/${theme}-favicon.png`;

			this.titleService.setTitle(
				`FTTX - ${configValues.prettyName || capitalize(operator)}`,
			);

			this.cssUrl = sanitizer.bypassSecurityTrustResourceUrl(`${theme}.css`);
		});
	}

}
