import { FormFieldModule, InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { IconsModule, SVGIconModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DrawerModule, LayoutModule } from '@progress/kendo-angular-layout';
import { MenusModule } from '@progress/kendo-angular-menu';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { PopoverModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { FilterModule } from '@progress/kendo-angular-filter';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

export const KendoModule = [
	InputsModule,
	SVGIconModule,
	ButtonsModule,
	DrawerModule,
	MenusModule,
	LayoutModule,
	DropDownsModule,
	DialogsModule,
	LabelModule,
	SwitchModule,
	ButtonsModule,
	ChartsModule,
	IconsModule,
	TooltipsModule,
	GridModule,
	NavigationModule,
	WindowModule,
	FilterModule,
	PopupModule,
	DateInputsModule,
	IntlModule,
	FormFieldModule,
	UploadsModule,
	PDFModule,
	IndicatorsModule,
	ProgressBarModule,
	PopoverModule,
];
