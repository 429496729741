	<app-auth-wrapper [title]="title">
		<form #forgotPassword="ngForm" class="k-form k-form-md">
			<p class="form-title">Enter your email address below</p>
			<fieldset class="k-form-fieldset">
				<kendo-formfield>
					<kendo-label class="field-label" text="Email"></kendo-label>
					<kendo-textbox #emailInput="ngModel" [(ngModel)]="email" [email]="true" [clearButton]="true" class="custom-input" name="email" required></kendo-textbox>
					<div *ngIf="emailInput.invalid && emailInput.touched">
						<kendo-formerror>Please enter a valid email address</kendo-formerror>
					</div>
				</kendo-formfield>
				<button (click)="sendResetLink()" [disabled]="forgotPassword.invalid" class="auth-form-button" kendoButton themeColor="primary">SEND RESET LINK</button>
			</fieldset>
		</form>
	</app-auth-wrapper>
