import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[appNumericOnly]',
})
export class NumericOnlyDirective {
	@Input() public appNumericOnly: boolean = true;

	constructor(private readonly ngControl: NgControl) {}

	@HostListener('input', ['$event'])
	public onInputChange(event: Event): void {
		if (!this.appNumericOnly)
			return;
		const input = event.target as HTMLInputElement;
		const initialValue = input.value;
		const numericValue = initialValue.replace(/[^0-9]*/g, '');
		if (initialValue !== numericValue) {
			input.value = numericValue;
			this.ngControl.control?.setValue(numericValue);
		}
	}
}
