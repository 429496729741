import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPagedResponse } from '@aex/ngx-toolbox';
import {
	ICondition,
	IConditionType,
	IDomain,
	IFullCondition,
	IFullStep,
	IOperation,
	IRule,
	IStep,
	IStepType,
	IStepTypeTransition,
	IConditionValue,
	IDomainField,
	ParamMetaData,
	IFullRule,
	IFullNewCondition, IRuleHistory,
} from '@aex/shared/common-lib';
import { AutomatedActionsApi } from '../api';
import { BaseApiService } from './base-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})

export class AutomatedActionsService extends BaseApiService {
	constructor(override readonly http: HttpClient) {
		super(http);
	}

	// Rules Calls
	public getRules(): Observable<IPagedResponse<IRule>> {
		return this.handleGetResponse<IPagedResponse<IRule>>(AutomatedActionsApi.rules());
	}

	public getRulesFull(): Observable<IPagedResponse<IFullRule>> {
		const params = new ParamMetaData({ handleError: 'automatedaction' }).withAllCount();
		return this.handleGetResponse<IPagedResponse<IFullRule>>(AutomatedActionsApi.rulesFull(), params);
	}

	public getRule(ruleId: number): Observable<IRule> {
		return this.handleGetResponse<IRule>(AutomatedActionsApi.rule(ruleId));
	}

	public getFullRule(ruleId: number): Observable<IFullRule> {
		return this.handleGetResponse<IFullRule>(AutomatedActionsApi.ruleFull(ruleId));
	}

	public runRule(ruleId: number): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.runRule(), {
			rule_id: ruleId,
		});
	}

	public createRule(rule: IRule): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createRule, {
			action_rule: {
				'rule_name': rule.rule_name,
				'description': rule.description,
			},
		});
	}

	public updateRule(ruleId: number, rule: IRule): Observable<IRule> {
		return this.handlePutResponse<IRule>(AutomatedActionsApi.updateRule(ruleId), {
			action_rule: {
				'rule_name': rule.rule_name,
				'description': rule.description,
				'enabled': rule.enabled,
				'deleted': rule.deleted,
			},
		});
	}

	//Rule History Calls
	public getRuleHistory(ruleId: number): Observable<IPagedResponse<IRuleHistory>> {
		const params = new ParamMetaData({ handleError: 'automatedaction' }).set('rule_id', ruleId)
    return this.handleGetResponse<IPagedResponse<IRuleHistory>>(AutomatedActionsApi.ruleHistory(), params);
  }

	// Condition Calls
	public getConditions(ruleId: number = null): Observable<IPagedResponse<ICondition>> {
		const paramMetaData = ruleId ? new ParamMetaData().set('rule_id', ruleId): new ParamMetaData();
		return this.handleGetResponse<IPagedResponse<ICondition>>(AutomatedActionsApi.conditions(), paramMetaData);
	}

	public getConditionsFull(ruleId: number = null): Observable<IPagedResponse<IFullCondition>> {
		const params = new ParamMetaData({ handleError: 'automatedaction' }).set('rule_id', ruleId).withAllCount();
		return this.handleGetResponse<IPagedResponse<IFullCondition>>(AutomatedActionsApi.conditionsFull(),	params);
	}

	public getCondition(conditionId: number): Observable<ICondition> {
		return this.handleGetResponse<ICondition>(AutomatedActionsApi.condition(conditionId));
	}

	public getFullCondition(conditionId: number): Observable<IFullCondition> {
		return this.handleGetResponse<IFullCondition>(AutomatedActionsApi.conditionFull(conditionId));
	}

	public createCondition(condition: ICondition): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createCondition, {
			action_rule_condition: condition,
		});
	}

	public createFullCondition(fullNewCondition: IFullNewCondition): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createFullCondition, {
			fullNewCondition,
		});
	}

	public updateCondition(conditionId: number, condition: ICondition): Observable<ICondition> {
		return this.handlePutResponse<ICondition>(AutomatedActionsApi.updateCondition(conditionId), {
			action_rule_condition: condition,
		});
	}

	// Condition Type Calls
	public getConditionTypes(): Observable<IPagedResponse<IConditionType>> {
		return this.handleGetResponse<IPagedResponse<IConditionType>>(AutomatedActionsApi.conditionTypes());
	}

	public getConditionType(conditionTypeId: number): Observable<IConditionType> {
		return this.handleGetResponse<IConditionType>(AutomatedActionsApi.conditionType(conditionTypeId));
	}

	public createConditionType(conditionType: IConditionType): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createConditionType, { conditionType });
	}

	public updateConditionType(conditionTypeId: number, conditionType: IConditionType): Observable<IConditionType> {
		return this.handlePutResponse<IConditionType>(AutomatedActionsApi.updateConditionType(conditionTypeId), { conditionType });
	}

	// Operations Calls
	public getOperations(): Observable<IPagedResponse<IOperation>> {
		return this.handleGetResponse<IPagedResponse<IOperation>>(AutomatedActionsApi.operations());
	}

	public getOperation(operationId: number): Observable<IOperation> {
		return this.handleGetResponse<IOperation>(AutomatedActionsApi.operation(operationId));
	}

	public createOperation(operation: IOperation): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createOperation, { operation });
	}

	public updateOperation(operationId: number, operation: IOperation): Observable<IOperation> {
		return this.handlePutResponse<IOperation>(AutomatedActionsApi.updateOperation(operationId), { operation });
	}

	// Condition Value Calls
	public getConditionValues(): Observable<IPagedResponse<IConditionValue>> {
		return this.handleGetResponse<IPagedResponse<IConditionValue>>(AutomatedActionsApi.conditionValues());
	}

	public getConditionValue(conditionValueId: number): Observable<IConditionValue> {
		return this.handleGetResponse<IConditionValue>(AutomatedActionsApi.conditionValue(conditionValueId));
	}

	public createConditionValue(conditionValue: IConditionValue): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createConditionValue, {
			action_rule_condition_value: {
				id: conditionValue.id,
				data_value: conditionValue.data_value,
				data_part: conditionValue.data_part,
				data_function: conditionValue.data_function,
				domain_field_id: conditionValue.domain_field_id,
			},
		});
	}

	public updateConditionValue(conditionValueId: number, conditionValue: IConditionValue): Observable<IConditionValue> {
		return this.handlePutResponse<IConditionValue>(AutomatedActionsApi.updateConditionValue(conditionValueId), {
			action_rule_condition_value: {
				id: conditionValue.id,
				data_value: conditionValue.data_value,
				data_part: conditionValue.data_part,
				data_function: conditionValue.data_function,
				domain_field_id: conditionValue.domain_field_id,
			},
		});
	}

	// Step Calls
	public getSteps(ruleId: number = null): Observable<IPagedResponse<IStep>> {
		const params = new ParamMetaData({ handleError: 'automatedaction' }).set('rule_id', ruleId).withAllCount();
		return this.handleGetResponse<IPagedResponse<IStep>>(AutomatedActionsApi.steps(), params);
	}


	public getStepsFull(ruleId: number = null): Observable<IPagedResponse<IFullStep>> {
		const params = new ParamMetaData({ handleError: 'automatedaction' }).set('rule_id', ruleId).withAllCount();
		return this.handleGetResponse<IPagedResponse<IFullStep>>(AutomatedActionsApi.stepsFull(), params);
	}

	public getStep(stepId: number): Observable<IFullStep> {
		return this.handleGetResponse<IFullStep>(AutomatedActionsApi.step(stepId));
	}

	public getFullStep(stepId: number): Observable<IFullStep> {
		return this.handleGetResponse<IFullStep>(AutomatedActionsApi.stepFull(stepId));
	}

	public createStep(step: IStep): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createStep, {
			action_rule_step: {
				rule_id: step.rule_id,
				step_type_id: step.step_type_id,
				step: step.step,
			},
		});
	}

	public updateStep(stepId: number, step: IStep): Observable<IStep> {
		return this.handlePutResponse<IStep>(AutomatedActionsApi.updateStep(stepId), {
			action_rule_step: {
				id: step.id,
				rule_id: step.rule_id,
				step_type_id: step.step_type_id,
				step: step.step,
			},
		});
	}

	// Step Type Calls
	public getStepTypes(): Observable<IPagedResponse<IStepType>> {
		return this.handleGetResponse<IPagedResponse<IStepType>>(AutomatedActionsApi.stepTypes());
	}

	public getStepType(stepTypeId: number): Observable<IStepType> {
		return this.handleGetResponse<IStepType>(AutomatedActionsApi.stepType(stepTypeId));
	}

	public createStepType(stepType: IStepType): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createStepType, { stepType });
	}

	public updateStepType(stepTypeId: number, stepType: IStepType): Observable<IStepType> {
		return this.handlePutResponse<IStepType>(AutomatedActionsApi.updateStepType(stepTypeId), { stepType });
	}

	// Step Type Transition Calls
	public getStepTypeTransitions(): Observable<IPagedResponse<IStepTypeTransition>> {
		return this.handleGetResponse<IPagedResponse<IStepTypeTransition>>(AutomatedActionsApi.stepTypeTransitions());
	}

	public getStepTypeTransition(stepTypeTransitionId: number): Observable<IStepTypeTransition> {
		return this.handleGetResponse<IStepTypeTransition>(AutomatedActionsApi.stepTypeTransition(stepTypeTransitionId));
	}

	public createStepTypeTransition(stepTypeTransition: IStepTypeTransition): Observable<string> {
		return this.handlePostResponse<string>(AutomatedActionsApi.createStepTypeTransition, {
			action_rule_step_transition: {
				domain_field_id: stepTypeTransition.domain_field_id,
        step_id: stepTypeTransition.step_id,
        from_status: stepTypeTransition.from_status,
        to_status: stepTypeTransition.to_status,
        transition_reason: stepTypeTransition.transition_reason,
      },
		});
	}

	public updateStepTypeTransition(stepTypeTransitionId: number, stepTypeTransition: IStepTypeTransition): Observable<IStepTypeTransition> {
		return this.handlePutResponse<IStepTypeTransition>(AutomatedActionsApi.updateStepTypeTransition(stepTypeTransitionId), {
			action_rule_step_transition: {
				id: stepTypeTransition.id,
				domain_field_id: stepTypeTransition.domain_field_id,
				step_id: stepTypeTransition.step_id,
				from_status: stepTypeTransition.from_status,
				to_status: stepTypeTransition.to_status,
				transition_reason: stepTypeTransition.transition_reason,
			},
		});
	}

	// Domain Calls
	public getDomains(): Observable<IPagedResponse<IDomain>> {
		return this.handleGetResponse<IPagedResponse<IDomain>>(AutomatedActionsApi.domains());
	}

	public getDomain(domainId: number): Observable<IDomain> {
		return this.handleGetResponse<IDomain>(AutomatedActionsApi.domain(domainId));
	}

	public updateDomain(domainId: number, domain: IDomain): Observable<IDomain> {
		return this.handlePutResponse<IDomain>(AutomatedActionsApi.updateDomain(domainId), { domain });
	}

	// Domain Field Calls
	public getDomainFields(searchable?: boolean, domainId?: number): Observable<IPagedResponse<IDomainField>> {
		let params = new ParamMetaData({ handleError: 'automatedaction' }).withAllCount();

		if (searchable !== undefined)
			params = params.set('searchable', searchable);

		if (domainId !== undefined)
			params = params.set('domain_id', domainId);


		return this.handleGetResponse<IPagedResponse<IDomainField>>(AutomatedActionsApi.domainFields(), params);
	}

	public getDomainField(domainFieldId: number): Observable<IDomainField> {
		return this.handleGetResponse<IDomainField>(AutomatedActionsApi.domainField(domainFieldId));
	}

	public updateDomainField(domainFieldId: number, domainField: IDomainField): Observable<IDomainField> {
		return this.handlePutResponse<IDomainField>(AutomatedActionsApi.updateDomainField(domainFieldId), {
			action_domain_field: {
				display_name: domainField.display_name,
				searchable: domainField.searchable,
				external_table: domainField.external_table,
			},
		});
	}
}
