import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SavingStates } from '../shared/enum';
import { map } from 'rxjs/operators';
import { BaseDataGrid } from '../components/shared/data-grid/base-data-grid';

@Injectable({
	providedIn: 'root',
})
export class GridModalService {
	private readonly savingStateSubject: BehaviorSubject<SavingStates> = new BehaviorSubject<SavingStates>(null);
	public readonly savingStateStream: Observable<SavingStates> = this.savingStateSubject.asObservable();
	public readonly canSaveStream: Observable<boolean> = this.savingStateStream.pipe(
		map((stateStream: SavingStates) => [SavingStates.ADD, SavingStates.BOTH].some(state => state === stateStream)),
	);
	public readonly canEditStream: Observable<boolean> = this.savingStateStream.pipe(
		map((stateStream: SavingStates) => [SavingStates.EDIT, SavingStates.BOTH].some(state => state === stateStream)),
	);

	private baseDataGridRef: BaseDataGrid;

	public setSavingState(state: SavingStates): void {
		this.savingStateSubject.next(state);
	}

	public setBaseDataGridRef(ref: BaseDataGrid): void {
		this.baseDataGridRef = ref;
	}

	public getBaseDataGridRef(): BaseDataGrid {
		return this.baseDataGridRef;
	}

}
