import { Component } from '@angular/core';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent {
	public newPassword: string = '';
	public confirmPassword: string = '';
	public readonly title: string = 'Log into your account';

	public resetPassword(): void {
		// 	Reset Password API
	}

}

