import { IPagedResponse } from '@aex/ngx-toolbox';
import {
	AuthType,
	IDeviceHistory,
	IDeviceStatus,
	IFullDeviceNetworkConfig,
	IFullDeviceNetworkStatus,
	INetworkState,
	IQrRedirect,
	IRadiusStatus,
	IWifiDevices,
	ParamMetaData,
} from '@aex/shared/common-lib';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceApi, PortalApi, StatusApi, WifiApi } from '../api';

@Injectable()
export class StatusService {
	constructor(private readonly http: HttpClient) {}

	public getNetworkStatus(networkId: string): Observable<INetworkState> {
		return this.http.get<INetworkState>(StatusApi.networkStatus(networkId), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getDeviceFromSerial(serialNumber: string): Observable<IDeviceStatus> {
		return this.http.get<IDeviceStatus>(StatusApi.deviceFromSerial(serialNumber), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getQrCodeRedirects(serialNumber: string): Observable<IQrRedirect> {
		return this.http
			.get<IQrRedirect>(PortalApi.qrRedirect(serialNumber), {
				params: new ParamMetaData({ authToken: AuthType.NONE }),
			})
			.pipe(map((response) => response));
	}

	public getFullServiceDeviceConfigFromSerial(serialNumber: string, portNo: string, serviceId: string): Observable<HttpEvent<IFullDeviceNetworkConfig>> {
		return this.http.get<IFullDeviceNetworkConfig>(`${StatusApi.fullServiceDeviceConfigFromSerial(serialNumber, portNo)}?refresh=false&service_guid=${serviceId}`, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
			observe: 'response',
		});
	}

	public getFullServiceDeviceStatusFromSerial(serialNumber: string, serviceId: string): Observable<IFullDeviceNetworkStatus> {
		return this.http.get<IFullDeviceNetworkStatus>(`${StatusApi.fullServiceDeviceStatusFromSerial(serialNumber)}?refresh=false&service_guid=${serviceId}`, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getServiceRadiusStatusFromSerial(serialNumber: string, portNo: string): Observable<IRadiusStatus> {
		return this.http.get<IRadiusStatus>(`${StatusApi.serviceRadiusStatusFromSerial(serialNumber, portNo)}?refresh=false`, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getDeviceHistory(serialNumber: string): Observable<IPagedResponse<IDeviceHistory>> {
		return this.http.get<IPagedResponse<IDeviceHistory>>(`${StatusApi.deviceHistory(serialNumber)}?page=1&count=10`, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getWifiDeviceInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.get<IWifiDevices>(`${WifiApi.wifiDevicesInfo(serialNumber)}`, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getPingTestInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.post<IWifiDevices>(`${DeviceApi.pingTest(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getTracerRouteInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.post<IWifiDevices>(`${DeviceApi.tracerRouteTest(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

	public getNsLookupInfo(serialNumber: string): Observable<IWifiDevices> {
		return this.http.post<IWifiDevices>(`${DeviceApi.nSLookupTest(serialNumber)}`, {}, {
			params: new ParamMetaData({authToken: AuthType.PROXIED}),
		});
	}

}
