import { WithDestroy } from '@aex/ngx-toolbox';
import { CustomerUserService, ICustomer, PORTAL } from '@aex/shared/common-lib';
import { AuthService, ConfigService } from '@aex/shared/root-services';
import { Component, OnDestroy } from '@angular/core';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

@Component({
	selector: 'app-user-menu',
	templateUrl: './user-navigation.component.html',
	styleUrls: ['../header.component.scss'],
})
export class UserNavigationMenuComponent extends WithDestroy() implements OnDestroy {
	public readonly profileLogo: string = 'assets/img/portal/avatar.svg';
	public selectedProfile: GroupResult;
	public areaList: GroupResult[];

	public currentUser: ICustomer;
	public readonly imageWidth: string = '35';
	public readonly imageHeight: string = '34';
	public readonly profileLink: string = PORTAL.manageProfilePage.name;
	public readonly manageProfileDescription: string = 'Manage Profile';

	public get profileName(): string {
		const name = `${this.currentUser?.first_name} ${this.currentUser?.last_name}`;
		return name || this.currentUser?.username;
	}

	// TODO reinclude when production urls have been updated
	// @ViewChild('portalIframeLogout') public portalIframeRef: ElementRef<HTMLIFrameElement>;
	// private isIframeReady = false;
	// public trustedUrl = SERVER_CONFIG.portalUrl;

	// private get portalAuthService(): PortalAuthService {
	// 	return this.authService as PortalAuthService;
	// }

	constructor(
		private readonly authService: AuthService,
		private readonly customerUserService: CustomerUserService,
		// TODO reinclude when production urls have been updated
		// private readonly windowPostMessageService: WindowPostMessageService,
		// private readonly navigationService: NavigationService,
		private readonly configService: ConfigService,
	) {
		super();
		this.noZombie(this.customerUserService.currentUserStream).subscribe((user) => {
			this.currentUser = user;
			// For below hardcoded data, refer to this sheet cell:
			// https://docs.google.com/spreadsheets/d/17wJKXzCXcXnwpAKjXWz4ra23BbhMlYvV71tLLwmNWzY/edit?gid=0#gid=0&range=D2
			this.areaList = groupBy(
				[
					{
						name: `${this.profileName} (You)`,
					},
					{
						name: 'Automation Exchange',
						type: 'Companies',
					},
					{
						name: 'Mock FNO',
						type: 'Companies',
					},
					{
						name: 'The Test ISP',
						type: 'Companies',
					},
				],
				[
					{
						field: 'type',
					},
				],
			) as GroupResult[];
			this.selectedProfile = this.areaList?.[0];
		});

		// TODO reinclude when production urls have been updated
		// this.windowPostMessageService.registerLogoutHandler();
		// this.noZombie(this.windowPostMessageService.isIframeReadyStream).subscribe((isReady: boolean) => (this.isIframeReady = isReady));
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		// TODO reinclude when production urls have been updated
		// this.windowPostMessageService.removeLogoutHandler();
		// reset iframe state
		// this.windowPostMessageService.isIframeReady = false;
	}

	// TODO reinclude when production urls have been updated
	// public initiateLogOut(): void {
	// 	const periodTime = 2000;
	// 	const maxRetries = 3;
	// 	let retryCount = 0;
	// 	this.navigationService.startLoading();

	// 	this.noZombie(interval(periodTime))
	// 		.pipe(
	// 			takeWhile(() => retryCount < maxRetries),
	// 			tap(() => retryCount++),
	// 		)
	// 		.subscribe(() => {
	// 			if (this.isIframeReady || retryCount === maxRetries)
	// 				this.logOut();
	// 		});
	// }

	// private logOut(): void {
	// 	try {
	// 		this.portalAuthService.initiatePortalLogOut(this.portalIframeRef);
	// 	} catch (error) {
	// 		console.error(error);
	// 		if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.InternalServerError && error.statusText === CLASSIC_PORTAL_ERROR_NOT_FOUND)
	// 			this.authService.gotoLogin();
	// 	} finally {
	// 		this.navigationService.stopLoading();
	// 	}
	// }

	public logOut(): void {
		this.authService.logout();
		this.authService.gotoLogin();
	}

	public navigateToClassicPortal(): void {
		window.location.href = this.configService.operatorPortalBaseUrl;
	}
}
