import { PORTAL } from '@aex/shared/common-lib';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
	public email: string = '';
	public password: string = '';
	public readonly title: string = 'Forgot your password?';

	constructor(private readonly router: Router) {
	}

	public sendResetLink(): void {
		// forgot password API
		this.router.navigate([PORTAL.checkEmail.name]).then();
	}
}
