import { DASHBOARD_TABS, PORTAL } from '@aex/shared/common-lib';
import { AuthService } from '@aex/shared/root-services';
import { HttpStatusCode } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
})
export class LoginComponent {
	public email: string = '';
	public password: string = '';
	public readonly title: string = 'Log into your account';
	public readonly errorMessage: string = 'Incorrect email or password';
	public showPassword: boolean = false;
	public showError: boolean = false;
	public readonly forgotPasswordLink = PORTAL.forgotPassword.path;

	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
	) { }

	public login(): void {
		this.authService.loginBoth(
			this.email,
			this.password,
		).subscribe(
			() => {
				this.router.navigate([PORTAL.dashboardPage.name, DASHBOARD_TABS.services.name]).then();
			},
			error => {
					this.showError = error?.error?.status === HttpStatusCode.Unauthorized;
			},
		);
	}

	public togglePassword(): void {
		this.showPassword = !this.showPassword;
	}
}
