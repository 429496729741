import { IAreaTypesData, IAreaData, ParamMetaData, AuthType } from '@aex/shared/common-lib';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AreaApi } from '../api';

@Injectable({
	providedIn: 'root',
})
export class AreaService {
	constructor(
		private readonly http: HttpClient,
	) { }

	public getAllAreaTypes(): Observable<HttpResponse<IAreaTypesData>> {
		return this.http.get<IAreaTypesData>(
			AreaApi.areaTypes(),
			{
				params: new ParamMetaData({ authToken: AuthType.USER }),
				observe: 'response',
			},
		);
	}

	public getAllAreas(id: number): Observable<HttpResponse<IAreaData>> {
		return this.http.get<IAreaData>(
			AreaApi.areasByType(id),
			{ observe: 'response' },
		);
	}

	public getAllSubAreas(id: string): Observable<HttpResponse<IAreaData>> {
		return this.http.get<IAreaData>(
			AreaApi.areasByParent(id),
			{ observe: 'response' },
		);
	}

}
