import { FAQ, IlineChartTimeline, IPaymentDetailsType, NetworkStates, PanelDataUrlSlug } from '@aex/shared/common-lib';


export const lineChartHistoryList: IlineChartTimeline[] = [
	{
		text: 'Today',
		value: 1,
	},
	{
		text: 'Yesterday',
		value: 2,
	},
	{
		text: 'Last 7 Days',
		value: 7,
	},
	{
		text: 'Last 30 Days',
		value: 30,
	},
];

export const history = [
	{
		Id: '1',
		CompanyName: 'Grid Cell',
		ContactName: 'Grid Cell',
		ContactTitle: 'Grid Cell',
	},
	{
		Id: '2',
		CompanyName: 'Grid Cell',
		ContactName: 'Grid Cell',
		ContactTitle: 'Grid Cell',
	},
	{
		Id: '3',
		CompanyName: 'Grid Cell',
		ContactName: 'Grid Cell',
		ContactTitle: 'Grid Cell',
	},
	{
		Id: '4',
		CompanyName: 'Grid Cell',
		ContactName: 'Grid Cell',
		ContactTitle: 'Grid Cell',
	},
];

export const comments = [
	{
		Date: '03/10/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Device',
		Detail: 'Modified by Client-Interface System on 2024-10-04 at 14:34:32',
	},
	{
		Date: '02/10/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Task',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/09/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/08/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Billing',
		Detail: 'Account Suspended due to lack of payment',
	},
	{
		Date: '03/07/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Device',
		Detail: 'PPPoE Username Updated: 30ED26E392B84E69BCC4',
	},
	{
		Date: '03/07/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Device',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/07/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/06/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/06/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/06/2024 14:34:32',
		'Modified By': 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
];


export const customerHistory: Record<string, string>[] = [
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Device',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
	{
		Date: '03/10/2023 14:34:32',
		ModifiedBy: 'Client-Interface System',
		Type: 'Service',
		Detail: 'Premise Updated',
	},
];

export const checksGridData: Record<string, string>[] = [{ Name: 'Cleartext-Password', Operator: ':=', Value: '2HWEPOJ' }];

export const attributesGridData: Record<string, string>[] = [
	{ Name: 'Framed-IP-Netmask', Operator: ':=', Value: '255.255.255.255' },
	{ Name: 'Framed-Protocol', Operator: ':=', Value: 'ppp' },
	{ Name: 'H3C-VPN-Instance', Operator: ':=', Value: 'zf-gp-aex-acclrt' },
];
export const authAttemptsGridData: Record<string, string>[] = [
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
	{ Date: '2023-10-30T13:53:05+02:00', Result: 'Access-Accept' },
];

export const activeSessionsGridData: Record<string, string>[] = [
	{ Start: '2023-10-30T13:53:05+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12', Length: '1h 0s' },
];
export const establishedSessionsGridData: Record<string, string>[] = [
	{ Start: '2023-10-30T13:53:05+02:00', End: '', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
	{ Start: '2023-10-30T13:53:05+02:00', End: '2023-10-30T12:24:45+02:00', 'IP Address': '172.16.0.7', 'NAS IP Address': '100.81.105.12' },
];

export const paymentHistoryGridData = [
	{
		id: 1,
		purchaseDate: 'Sep 25, 2023',
		amount: 'ZAR 275.50',
		status: 'Fulfilled',
		collectionDay: '25',
		paymentHistoryDetail: [
			{
				id: 1,
				paymentHistoryId: 1,
				date: 'Aug 1, 2023',
				amount: 'ZAR 275.50',
				mediumType: 'OffSystem',
				mediumStatus: 'Valid',
				paymentMedium: 'N/A',
				tpppReference: 'N/A',
				status: 'Success',
				result: 'Off-system payment applied',
			},
		],
	},
];

export const paymentHistoryAmountDetailGridData = [
	{
		description: 'Off System',
		amount: 'ZAR 275.50',
		allocatedBy: 'User',
		comment: 'Product Price',
		allocationType: 'Shop 2 Shop',
	},
];

export const paymentDetails: IPaymentDetailsType[] = [
	{
		name: 'Payment Start Date',
		value: 'Jun 1, 2024',
	},
	{
		name: 'Payment End Date',
		value: 'Jun 1, 2025',
	},
	{
		name: 'Plan Status',
		value: 'Active',
	},
	{
		name: 'Collection Day',
		value: '1',
		type: 'selectable',
	},
	{
		name: 'Amount',
		value: 'ZAR 549.00',
	},
	{
		name: 'Active Medium',
		value: 'Test',
	},
	{
		name: 'Medium Type',
		value: 'Credit Card',
	},
	{
		name: 'Medium Status',
		value: 'Valid',
	},
	{
		name: 'Manual payment allowed',
		value: 'No',
	},
	{
		name: 'Is payment overdue?',
		value: 'No',
	},
	{
		name: 'Is last payment pending?',
		value: 'No',
	},
];

export const collectionOptions = [
	{ label: '10', value: '10' },
	{ label: '15', value: '15' },
	{ label: '20', value: '20' },
	{ label: '25', value: '25' },
	{ label: '30', value: '30' },
];

export const paymentMethods = [
	{
		cardNo: '4111111111111111',
		expDate: '07/23',
		isDefault: true,
		type: 'Visa Debit Card',
		isValid: true,
	},
];

export const paymentHistoryColumns = [
	{
		field: 'purchaseDate',
		title: 'Purchase Date',
	},
	{
		field: 'amount',
		title: 'Amount',
	},
	{
		field: 'status',
		title: 'Status',
	},
	{
		field: 'collectionDay',
		title: 'Collection Day',
	},
];

export const paymentHistoryDetailsColumns = [
	{
		field: 'date',
		title: 'Date',
	},
	{
		field: 'amount',
		title: 'Amount',
	},
	{
		field: 'mediumType',
		title: 'Medium Type',
	},
	{
		field: 'mediumStatus',
		title: 'Medium Status',
	},
	{
		field: 'paymentMedium',
		title: 'Payment Medium',
	},
	{
		field: 'tpppReference',
		title: 'TPPP Reference',
	},
	{
		field: 'status',
		title: 'Status',
	},
	{
		field: 'result',
		title: 'Result',
	},
];

export const paymentHistoryAmountDetailColumns = [
	{
		field: 'description',
		title: 'Description',
	},
	{
		field: 'amount',
		title: 'Amount',
	},
	{
		field: 'allocatedBy',
		title: 'Allocated By',
	},
	{
		field: 'comment',
		title: 'Comment',
	},
	{
		field: 'allocationType',
		title: 'Allocation Type',
	},
];

export const networkPanelData = [
	{
		title: 'General',
		urlSlug: PanelDataUrlSlug.GENERAL,
		expanded: false,
		state: NetworkStates.LOADING,
	},
	{
		title: 'Layer 2',
		urlSlug: PanelDataUrlSlug.LAYER_TWO,
		expanded: false,
		state: NetworkStates.LOADING,
	},
	{
		title: 'Layer 3',
		urlSlug: PanelDataUrlSlug.LAYER_THREE,
		expanded: false,
		state: NetworkStates.LOADING,
	},
	{
		title: 'Advanced',
		urlSlug: PanelDataUrlSlug.ADVANCED,
		expanded: false,
	},
	{
		title: 'Device',
		urlSlug: PanelDataUrlSlug.DEVICE,
		expanded: false,
	},
	{
		title: 'Advanced Actions',
		urlSlug: PanelDataUrlSlug.ADVANCED_ACTOINS,
		expanded: false,
	},
];

export const portal2FaqV1Data: FAQ[] = [
	{
		title: 'What are the new features introduced in the updated portal? ',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'Portal 2.0 offers a redesigned front end that is easy to navigate. We have added the following new features to enhance the capabilities of the portal:',
				],
			},
			{
				list: true,
				listType: 'ol',
				content: [
					// eslint-disable-next-line max-len
					'Global search: A new search functionality available on the home page that allows you to search for a customer or a feature and will suggest results in the results bar. You can then click on one of the suggestions to take you to that customer/feature. The global search function will also allow you to search for old terms (such as services, work orders) and populate results based on the new features.',
					'Single pane dashboard: The new dashboard appears on the home page and provides you with a holistic view of active customers and status\' of Tasks.',
					// eslint-disable-next-line max-len
					'Tool tips: We’ve designed Portal 2.0 with the aim to help you navigate the system with ease. The portal has tool tips that are always available to give you insight into how to use a function.',
				],
			},
			{
				list: false,
				listType: null,
				content: [
					'In addition, Portal 2.0 boasts a new look and feel for the Service view now known as the Customer view and the Work Order view, now known as Tasks.',
				],
			},
		],
	},
	{
		title: 'How do I access and use the new features in the new portal?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'Portal 2.0 has a left navigation pane and all features (both new and old) are accessible from one of these navigation items.',
				],
			},
			{
				list: true,
				listType: 'ol',
				content: [
					'Global Search is available on the home screen.',
					'Single pane dashboard is available on the home screen.',
					'Tool tips appear throughout the portal.',
				],
			},
		],
	},
	{
		title: 'How do the new features integrate with existing functionality in the portal?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'All existing functionality from the portal has been mapped to Portal 2.0, so you are still able to complete any task or function.',
				],
			},
		],
	},
	{
		title: 'Where can I find the Task (Work Orders) that have been assigned to me?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'Work orders have been redesigned to be called Tasks. From the home page, click on the Tasks tab. Here you will be able to view all the Tasks that have been assigned to you, your team if you are a team leader, or all Tasks that have been created.',
				],
			},
		],
	},
];

export const portal2FaqV2Data: FAQ[] = [
	{
		title: 'Where can I find historical/completed Tasks (Work Orders)?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'You can access this from the Tasks tab from the home page or alternatively, you can select a customer, click on Tasks from the customer view and view that customers Task history.',
				],
			},
		],
	},
	{
		title: 'Where can I find service information?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'Services are now refocused to be centered around customer information. You can access the customer information from the home page by clicking on the customers tab. Here you will be able to view all customers, and when selected you can view all active services associated to that customer.',
				],
			},
		],
	},
	{
		title: 'How do I manage the entity that I am currently working on?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'At the top, right hand corner of the screen is your user management icon. Here you can manage your profile and toggle between entities.',
				],
			},
		],
	},
];

export const generalFaqV1Data: FAQ[] = [
	{
		title: 'What is the Operational Dashboard?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'This is a dashboard displayed on the portal home page which displays a count of all Service Statuses of Devices discoverable on the network as well as all current Tasks and their respective statuses.',
				],
			},
		],
	},
	{
		title: 'What is a Service (Customer)?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'A Service, also known as a Customer, is a Person (User) with both a Premise (Address/Lat Long) and a Product (Internet package) associated is a Service. We refer to the 3 Ps as a Service. (Person, Premise and Package).',
				],
			},
		],
	},
	{
		title: 'What is the Customer view?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'When a customer is selected, you get a full view of all information related to that customer such as Tasks (Work Orders), Addresses and Products (Services) associated to that customer. The customer view will highlight all information related to that customer, and allows for dynamic population of certain information depending on the service that is selected (if more than one exists for that customer).',
				],
			},
		],
	},
	{
		title: 'What is the Product view?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'A service is in essence a product that has been linked to a customer, but this is not a 1-1 mapping. A customer could have multiple services, both active and inactive. The approach would be to not view this in isolation either, but rather as an attribute of the customer. Products can be accessed and viewed from the customer view, with all products that are linked to that customer presented at the top of the screen. You can then navigate between products for a customer and the data presented will dynamically update to match the service you are viewing.',
				],
			},
		],
	},
	{
		title: 'What is a Task (Work Order)?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'A Task, also known as a Work Order, is a set of steps organized in a flow to perform actions against a Service. Examples of Tasks are Installation, Change of Service, Provisioning',
				],
			},
		],
	},
	{
		title: 'What is Task view?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'Work to be done on this product (installation, changes, cancellations, etc.) are just tasks that need to be completed and are associated to a specific end user and will never live in isolation. Each Task is associated to a user and the appropriate service that the task needs to be completed against.',
				],
			},
			{
				list: false,
				listType: null,
				content: [
					'Tasks will be accessed in the following ways:',
				],
			},
			{
				list: true,
				listType: 'ul',
				content: [
					// eslint-disable-next-line max-len
					'Customer view: When a customer is selected, you can choose which product you want to view (if the customer has more than one product). If that product has an active Task, this will be presented upfront. Or click on the Tasks tab on the left navigation pane to view all historical Tasks.',
					'Tasks: There will be a Tasks tab on the main navigation pane where the portal user can view all Tasks that are assigned to them.',
				],
			},
		],
	},
	{
		title: 'How do I manage Tasks (Work Orders)?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'Manage Status Allocation is where Task (Work Order) Statuses are managed in the portal. Allocation refers to assigning to both company and people within that company. ',
				],
			},
		],
	},
	{
		title: 'What are Unassigned Devices?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'Unassigned Devices refers to the total of devices that are on the network with no active provisioning/service associated.',
				],
			},
		],
	},
	{
		title: 'What is an alert?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'An alert is a notification to draw attention to a problem or an issue. Within the portal, alerts will make the blocks on the Device status appear orange (amber). ',
				],
			},
		],
	},
	{
		title: 'What is an alarm?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'An alarm is a notification to draw attention to a very serious problem or an issue. Alarms require urgent attention and will make the Device Status blocks red. ',
				],
			},
		],
	},
];

export const generalFaqV2Data: FAQ[] = [
	{
		title: 'What is a VLAN?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'A virtual local area network (VLAN) is a virtualized connection that connects multiple devices and network nodes from different LANs into one logical network. The VLAN consists of an STAG/CTAG: STAG (Outer VLAN) and a CTAG (Inner VLAN).  A VLAN has a range which is set and supplied by the ISPs. ',
				],
			},
		],
	},
	{
		title: 'What is a CTAG/ CVLAN tag?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'A CTAG/ CVLAN tag is the Customer VLAN Tag and it identifies the VLAN to which the frame belongs when it is transmitted in the customer network.',
				],
			},
		],
	},
	{
		title: 'What is a STAG/ SVLAN tag?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'A STAG/ SVLAN tag is the Service VLAN Tag and it identifies the VLAN to which the QinQ frame belongs when it is transmitted in the service provider network. The service provider allocates the SVLAN tag to the customer.',
				],
			},
		],
	},
	{
		title: 'What is the Cancellation Effective Date?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'The Cancellation Effective Date is when the cancellation of a service will be actioned by the system.',
				],
			},
		],
	},
	{
		title: 'What is the Effective Date?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'The Effective Date is when a service will be “On Network” or provisioned.',
				],
			},
		],
	},
	{
		title: 'What is the ISP Effective Date?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'The ISP Effective Date is when the service is billable to the customer (end-user).',
				],
			},
		],
	},
	{
		title: 'What is a FSAN?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'A FSAN or Full-Service Access Network is an optical access network that provides high-speed internet. ',
				],
			},
		],
	},
	{
		title: 'What is the FSAN Number?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					// eslint-disable-next-line max-len
					'The FSAN Number or Full-Service Access Network Number is the Serial Number on your ONT (on occasion the MAC number is used instead). During Installation the FSAN Number is assigned to the Premise where the ONT is installed. It is used to Identify the fiber line to the Premise. The FSAN number can be found on the back or bottom of the ONT. ',
				],
			},
		],
	},
	{
		title: 'What is NMS?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'NMS stands for Network Management System and this identifies, configures, monitors, updates and troubleshoots network devices.',
				],
			},
		],
	},
	{
		title: 'What is an OLT?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'An OLT or Optical Line Terminal is the device used to connect to a network. It is related to the Point of Presence (POP).',
				],
			},
		],
	},
	{
		title: 'What is an ONT?',
		details: [
			{
				list: false,
				listType: null,
				content: [
					'An ONT or Optical Network Terminal is the device which is installed within a premise which connects the home to the wider network.',
				],
			},
		],
	},
];
