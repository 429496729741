import { ILoaderDef, IPagedResponse, NavigationService } from '@aex/ngx-toolbox';
import { NocApi, ProductService, ServiceApi, WifiApi, NIDApi } from '@aex/shared/apis';
import {
	ACTIVE_STATUSES,
	AuthType,
	IFullService,
	IFullServiceResponse,
	INidInstall,
	INode,
	IProduct,
	IProvider,
	IService,
	IServiceChange,
	IServiceCreate,
	IServiceOrder,
	IWifiDevice,
	IWifiDeviceCount,
	IWifiFrequency,
	IWifiInformation,
	IWorkOrderType,
	ParamMetaData,
} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, MonoTypeOperatorFunction, Observable, of, throwError, zip } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { CustomerService } from './customer.service';


@Injectable({
	providedIn: 'root',
})
export class ServiceSettingsService {
	constructor(
		private readonly http: HttpClient,
		private readonly navigationService: NavigationService,

		private readonly customerService: CustomerService,
		private readonly productService: ProductService,
	) { }
	public orderServiceLoader: ILoaderDef = { loader: 'order-service'};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private static buildQuery(field: string, value: string): any {
		return {
			query: {
				bool: {
					must: [
						{
							query_string: {
								query: `${field}:${value}`,
							},
						},
					],
				},
			},
		};
	}

	public getFullService(serviceId: string): Observable<IFullServiceResponse> {
		return this.http.get<IFullServiceResponse>(ServiceApi.fullService(serviceId), {
			params: new ParamMetaData({ loader: false }),
		});
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public cancelService(serviceId: string): Observable<any> {
		return this.http.delete(ServiceApi.service(serviceId), {
			params: new ParamMetaData({ handleError: 'serviceCancellation'}),
		});
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public createServiceChange(serviceChange: IServiceChange): Observable<any> {
		return this.http.post(ServiceApi.serviceChange, serviceChange, {
			params: new ParamMetaData({ handleError: 'serviceChange', authToken: AuthType.PROXIED}),
		});
	}

	public getServices(): Observable<IPagedResponse<IService>> {
		return this.http.get<IPagedResponse<IService>>(ServiceApi.services, {
			params: new ParamMetaData({ handleError: 'services'})
				.set('customer', this.customerService.currentUser.id).withAllCount(),
		});
	}

	public fetchFullServicesFromApi(serviceIds: string[], timeout: null | false): Observable<IFullService[]> {
		return forkJoin(serviceIds.map(id => {
			return this.http.get<IFullServiceResponse>(ServiceApi.fullService(id), {
				params: new ParamMetaData({ loader: false, timeout }),
			}).pipe(switchMap(fs => {
				return fs.full_service.premise?.asset_reference && ACTIVE_STATUSES.includes(fs.full_service.service.status_id)
					?
					this.productService.getDeviceFromSerial(fs.full_service.premise.asset_reference)
						.pipe(
							map(device => {
								fs.full_service.status = device;
								return fs.full_service;
							}),
						)
					: of(fs.full_service);
			}));
		}));
	}

	public getFullServices(loader?: string): Observable<IFullService[]> {
		const q = ServiceSettingsService.buildQuery('service.customer_id', this.customerService.currentUser?.id);
		this.navigationService.startLoading({ loader });
		return this.http.post<IPagedResponse<IFullService>>(ServiceApi.servicesSearch, q).pipe(
			map(r => r.items),
			finalize(() => this.navigationService.stopLoading({ loader })),
		);
	}

	public updateWiFiCredentials(serviceId: string, serviceBody: Partial<IService>): Observable<IService> {
		return this.http.put<IService>(ServiceApi.service(serviceId), {service: serviceBody});
	}
	public updateService(serviceId: string, order: Partial<IServiceOrder>): Observable<void> {
		return this.http.put<void>(ServiceApi.service(serviceId), order, {
			params: new ParamMetaData({ handleError: 'services' }),
		});
	}

	public updateFullService(serviceId: string, service: Partial<IFullService>): Observable<Partial<IFullService>> {
		return this.http.put<Partial<IFullService>>(ServiceApi.service(serviceId), service, {
			params: new ParamMetaData({ handleError: 'services' }),
		});
	}

	public getServiceFromSerial(serialNumber: string): Observable<IService[]> {
		return this.http.get<IPagedResponse<IService>>(ServiceApi.serviceFromSerial(serialNumber)).pipe(map(r => r.items));
	}

	public getServicesFromSerialNumber(serialNumber: string): Observable<Partial<IFullService>[]> {
		return zip(
			this.productService.getDeviceFromSerial(serialNumber),
			this.getServiceFromSerial(serialNumber),
			this.productService.getProducts(),
			this.productService.getProviders())
			.pipe(
				map(([device, services, products, providers]) =>
					services.filter(serv => serv.status === 'Active' || serv.status === 'Activation in Progress')
						.map(service => {
							const productId: string = service.product_id;
							const providerId: string = service.provider_id;
							const product: IProduct = products.find(prod => prod.id === productId);
							const provider: IProvider = providers.find(prov => prov.id === providerId);

							return {
								service,
								provider,
								isp_product: product,
								device_status: device,
							};
						}),
				),
			);
	}

	public orderService(order: Partial<IServiceOrder>): Observable<IService> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.http.post<any>(ServiceApi.services, order, {
			observe: 'response',
			params: new ParamMetaData({ loader: this.orderServiceLoader, handleError: 'services'}),
		}).pipe(
			switchMap((res) => {
				return this.http.get<IService>(res.headers.get('Location'));
			}),
		);
	}

	public orderServiceFull(order: Partial<IServiceCreate >): Observable<Partial<IServiceCreate >> {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return this.http.post<any>(ServiceApi.servicesFull, order, {
			params: new ParamMetaData({ loader: this.orderServiceLoader, handleError: 'services', authToken: AuthType.PROXIED}),
		});
	}

	public getServiceWorkOrderTypes(serviceId: string): Observable<IWorkOrderType[]> {
		return this.http.get<IWorkOrderType[]>(ServiceApi.workOrderTypes(serviceId), {
			params: new ParamMetaData({ handleError: 'services' }, { authToken: AuthType.PROXIED }),
		},
		);
	}

	public getServiceNodes(assetRef: string): Observable<INode> {
		return this.http.get<INode>(NocApi.serviceNodes(assetRef));
	}

	public getConnectedWifiDevices(assetRef: string): Observable<IWifiInformation> {
		return this.http.get<IWifiInformation>(WifiApi.wifiDevices(assetRef), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getWifiFrequencyData(assetRef: string): Observable<IWifiFrequency> {
		return this.http.get<IWifiFrequency>(WifiApi.wifiInformation(assetRef), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getWifiDeviceCount(assetRef: string): Observable<IWifiDeviceCount> {
		return this.http.get<IWifiDeviceCount>(WifiApi.deviceCount(assetRef), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public updateDeviceAlias(mac: string, aliasName: string): Observable<IWifiDevice> {
		return this.http.put<IWifiDevice>(WifiApi.aliasName(mac), aliasName, {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }).set('alias', aliasName)});
	}
	public orderWorkorderNID(order: Partial<INidInstall>): Observable<INidInstall> {
		return this.http.post<INidInstall>(NIDApi.nidInstalls, order, {
			params: new ParamMetaData({ loader: this.orderServiceLoader, handleError: 'services', authToken: AuthType.PROXIED}),
		});
	}

	public getCurrentNidService(premise: string): Observable<INidInstall> {
		return this.http.get<INidInstall>(NIDApi.serviceWithNIDInstalled(premise), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getNidServiceByQueryLatLong(latitude: number, longitude: number): Observable<INidInstall> {
		return this.http.get<INidInstall>(NIDApi.installs(latitude, longitude), {
			params: new ParamMetaData({ authToken: AuthType.PROXIED }),
		});
	}

	public getService(serviceId: string): Observable<IService> {
		return this.http.get<IService>(ServiceApi.service(serviceId), {
			params: new ParamMetaData({handleError: 'files'}),
		});
	}
}

export function catchDbStoreError<T>(dbResults: T[]): MonoTypeOperatorFunction<T[]> {
	return catchError(error => !dbResults.length
		? throwError(error)
		// Means we fall back to the cache, if there is a problem with the fetch
		: of(dbResults));
}
