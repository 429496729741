import { NavigationService, StorageService } from '@aex/ngx-toolbox';
import { ConfigApi } from '@aex/shared/apis';
import {
	AuthType, ConfigFile, ENVIRONMENT_CONFIG_TOKEN, IEnvironment, IGlobalConfig, ParamMetaData,
} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Directive, Inject } from '@angular/core';
import { capitalize } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let STARTUP_DATA: any = {};

@Directive()
export abstract class BaseConfigService {

	protected _fnoName: string;

	protected readonly configValuesSubject = new BehaviorSubject<IGlobalConfig>(null);
	public readonly configValuesStream = this.configValuesSubject.asObservable();

	protected readonly operatorSubject = new BehaviorSubject<string>(null);
	public readonly operatorStream = this.operatorSubject.asObservable();

	protected readonly changesSubject = new BehaviorSubject<boolean>(false);
	protected readonly _changesStream = this.changesSubject.asObservable();

	public readonly passwordMinLengthDefault = 6;

	constructor(
		protected readonly http: HttpClient,
		protected readonly navigationService: NavigationService,
		protected readonly storageService: StorageService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) protected readonly environment: IEnvironment,
	) { }

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public get startUpData(): any {
		return STARTUP_DATA;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public set startUpData(value: any) {
		STARTUP_DATA = value;
	}

	public get fnoName(): string {
		return this._fnoName;
	}
	public set fnoName(value: string) {
		this._fnoName = value;
	}

	public get operator(): string { return this._fnoName ?? this.environment.override?.operator ?? this.environment.operator; }
	public get configName(): string { return this.getConfigName(); }
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected _configValues: IGlobalConfig = {} as any as IGlobalConfig;
	public set configValues(value: IGlobalConfig) {
		this._configValues = value;
		this.configValuesSubject.next(value);
	}

	public get configValues(): IGlobalConfig { return this._configValues; }

	public set confirmChanges(changed: boolean) {
		this.changesSubject.next(changed);
	}

	public get getConfirmChanges(): Observable<boolean> {
		return this._changesStream;
	}

	public get operatorServerAlias(): string {
		return this.configValues.operatorServerAlias ?? this.operator;
	}

	public get operatorPrettyName(): string {
		return this.configValues.prettyName || capitalize(this.operator);
	}

	public get operatorTicketUrl(): string {
		return this.configValues.supportServiceDeskUrl;
	}

	public get fnoPortalTwoBaseUrl(): string {
		return `${this.configValues.fnoPortalTwoBaseUrl}/login`;
	}

	public get operatorDateFormat(): string {
		return this.configValues.dateFormat;
	}

	public get operatorPortalBaseUrl(): string {
		return this.configValues.fnoPortalBaseUrl;
	}

	public get operatorBehaviorSubject(): BehaviorSubject<string> {
		return this.operatorSubject;
	}

	public get operatorId(): string {
		return this.configValues.operatorId;
	}

	public getFnoConfig(fnoName: string, environment: string): Observable<ConfigFile> {
		return this.http.get<ConfigFile>(ConfigApi.config(this.getConfigFile(fnoName), environment), {
			params: new ParamMetaData({ authToken: AuthType.NONE }),
		});
	}

	protected getConfigFile(operator: string): string {
		return this.environment.override?.configFile ?? operator;
	}

	public getConfigName(): string {
		return this.environment.override?.configName ?? this.environment.name;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public store(key: string, value: any): void {
		this.storageService.store(key, value, this._configValues?.encryptLocalStore);
	}

	public retrieve(key: string): string {
		return this.storageService.retrieve(key, this._configValues?.encryptLocalStore);
	}

	public retrieveObject<T>(key: string): T {
		return this.storageService.retrieveObject(key, this._configValues?.encryptLocalStore);
	}

}
