<div class="faq-container">
	<h3 class="layout-heading">Frequently Asked Questions</h3>
	<h5 class="sub-heading">Introducing Portal 2.0</h5>
	<div class="faqs-wrapper">
		<div class="faqs">
			<kendo-expansionpanel
				*ngFor="let item of portalFaqsV1"
				[expanded]="false"
				[title]="item.title"
			>
				<div class="content">
					<app-faq-panel-content [item]="item"></app-faq-panel-content>
				</div>
			</kendo-expansionpanel>
		</div>
		<div class="faqs">
			<kendo-expansionpanel
				*ngFor="let item of portalFaqsV2"
				[expanded]="false"
				[title]="item.title"
			>
				<div class="content">
					<app-faq-panel-content [item]="item"></app-faq-panel-content>
				</div>
			</kendo-expansionpanel>
		</div>
	</div>

	<h5 class="sub-heading secondary">General FAQ</h5>
	<div class="faqs-wrapper">
		<div class="faqs">
			<kendo-expansionpanel
				*ngFor="let item of generalFaqV1"
				[expanded]="false"
				[title]="item.title"
			>
				<div class="content">
					<app-faq-panel-content [item]="item"></app-faq-panel-content>
				</div>
			</kendo-expansionpanel>
		</div>
		<div class="faqs">
			<kendo-expansionpanel
				*ngFor="let item of generalFaqV2"
				[expanded]="false"
				[title]="item.title"
			>
				<div class="content">
					<app-faq-panel-content [item]="item"></app-faq-panel-content>
				</div>
			</kendo-expansionpanel>
		</div>
	</div>
</div>
