export const CONSTANTS = {
    passwordResetLinkSuccess: 'If an account exists, an e-mail with a link to reset your password will be sent.',
	  passwordActivationSuccess: 'If an account exists, an e-mail with a link to activate your account will be sent.',
    defaultThankYouMessage: 'An email with your order details has been sent to you. You can also view your orders on your account',
    thankYouReviewMessage: 'An email with your order details has been sent to you. Please review your payment details.',
    passwordResetSuccess: 'Password has been reset.',
    termsAndConditionsMessage: 'By placing an order you agree to the Terms and Conditions.',
    fnoOptInMessage: 'Receive special offers from us via SMS and/or email.',
    ispOptInMessage: 'Receive special offers from our Internet Service Providers on our Network.',
    POPIErrorMessage: 'You need to consent to the usage of your personal information before you can register',
    termsAndConditionsErrorMessage: 'You need to accept Terms and Conditions before you can register',
    phoneNumberLabel: 'Phone Number',
    altPhoneNumberLabel: 'Alternative Phone Number',
    businessNumberLabel: 'Registration Number',
    promoCodeLabel: 'Promo Code',
    suburbLabel: 'Suburb',
    provinceLabel: 'Province',
    postalCodeLabel: 'Postal Code',
    debitLabel: 'Debit',
    capTitle: 'Product Type',
    cappedLabel: 'Capped',
    uncappedLabel: 'Uncapped',
    defaultCurrency: 'R',
    coreProduct: 'Core-EndToEnd',
    cardPaymentSelectedMessage: 'Due to security reasons, credit card details will be captured on successful placement of the order',
    bankAccountSelectedMessage: 'Due to security reasons, ACH details will be captured on successful placement of the order',
    setActiveLabel: 'Set Details to Active',
    activeMediumLabel: '',
    dayBundle: 'Day-bundle',
    checkoutButtonText: 'Place Order',
    checkoutDeferredSelectedButtonText: 'Confirm and Continue',
    paymentCardAddText: 'Add credit payment method',
    paymentLinkAddText: 'Add payment link payment method',
    requiredDocumentsRica: [
        'Identity Document',
        'Proof of Address',
        'Company Registration',
    ],
};
