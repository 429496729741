<kendo-grid
	(columnReorder)="onColumnReorder($event)"
	(pageChange)="pageChange($event)"
	(sortChange)="sortChange($event)"
	[data]="gridView"
	[pageSize]="pageSize"
	[pageable]="{
		buttonCount: pagesCountToPaginate,
		info: info,
		type: type,
		pageSizes: pageSizes,
		previousNext: previousNext,
		position: position
	}"
	[reorderable]="true"
	[skip]="skip"
	[sort]="sort"
	[sortable]="sortSettings"
	class="data-grid-container max-h-app-size-19-1"
	data-testid="data-grid-container"
>
	<ng-container *ngFor="let column of gridColumns; let first = first; let i = index">
		<kendo-grid-column [field]="column?.name" [title]="column?.heading" [width]="columnWidth">
			<ng-template kendoGridCellTemplate let-dataItem>
				<div *ngIf="dataItem[column?.name] !== 'null'; else noData" class="flex items-center gap-2">
					<div [ngClass]="chipStatusMapping[column.name + '-' + dataItem[column.name]]" *ngIf="column?.statusField" class="chip"></div>
					<span (click)="goToDetail(dataItem)" [ngClass]="{ 'text-common-primary underline': column.heading === highlightedHeading }" class="cursor-pointer">
						{{ !column.dateTimeColumn ? dataItem[column?.name] : dataItem[column?.name] | date: dateFormat }}
					</span>
				</div>
				<ng-template #noData>
					<span class="flex justify-center"> -- </span>
				</ng-template>
			</ng-template>
		</kendo-grid-column>
	</ng-container>

	<ng-template data-testid="data-grid-pager" kendoPagerTemplate>
		<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
		<kendo-pager-numeric-buttons [buttonCount]="pagesCountToPaginate"></kendo-pager-numeric-buttons>
		<kendo-pager-next-buttons></kendo-pager-next-buttons>
		<kendo-pager-info></kendo-pager-info>
		<kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
	</ng-template>
	<kendo-grid-pdf [allPages]="true" [landscape]="true" fileName="data.pdf" paperSize="A4"></kendo-grid-pdf>
</kendo-grid>
