import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
	selector: '[appPasswordValidator]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: PasswordValidatorDirective,
			multi: true,
		},
	],
})
export class PasswordValidatorDirective implements Validator {
	constructor() {
	}

	public validate(control: AbstractControl): ValidationErrors | null {
		return this.validatePassword(control);
	}

	private validatePassword(control: AbstractControl): ValidationErrors | null {
		const value: string = control.value || '';

		if (!value)
			return null;

		const errors: ValidationErrors = {};

		errors.lowercase = !/[a-z]/.test(value);
		errors.uppercase = !/[A-Z]/.test(value);
		errors.specialChar = !/[\W_]/.test(value);
		errors.minlength = value.length < 8;
		return Object.values(errors).some(value => value === true) ? errors: null;
	}
}
