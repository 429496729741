import { CustomerService, ProxyApi } from '@aex/shared/apis';
import { AuthType, ENVIRONMENT_CONFIG_TOKEN, IAuthResponse, ICustomer, IEnvironment, KEY_AUTH_TOKEN, ParamMetaData, PORTAL } from '@aex/shared/common-lib';
import { AuthService, ConfigService } from '@aex/shared/root-services';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isNil } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CLASSIC_PORTAL_ERROR_NOT_FOUND } from '../shared/constant';
import { PortalConfigService } from './portal-config.service';

@Injectable()
export class PortalAuthService extends AuthService {

	private readonly cookieAuthLogoffMsg = 'cookieAuthLogOff';

	private readonly classicPortalIframeError =  new HttpErrorResponse(
		{ status: HttpStatusCode.InternalServerError, statusText: CLASSIC_PORTAL_ERROR_NOT_FOUND},
	);

	constructor(
		http: HttpClient,
		router: Router,
		configService: ConfigService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
		private readonly customerService: CustomerService,
	) {
		super(http, router, configService, environment);
	}

	private get portalConfigService(): ConfigService {
		return this.configService as PortalConfigService;
	}

	public override setAuthToken(value: string): void {
		super.setAuthToken(value);
		this.portalConfigService.store(KEY_AUTH_TOKEN, value);
	}

	public override bustCaches(): Observable<void> {
		this.customerService.currentUser = null;
		return super.bustCaches();
	}

	public override gotoLogin(): void {
		this.router.navigate([PORTAL.loginPage.path]).then();
	}

	public override doGetLoginCustomer(username: string): Observable<ICustomer> {
		return this.customerService.getCustomerPremises(username);
	}

	protected override getToken(key: string): string {
		return this.portalConfigService.retrieve(key);
	}

	public setPortalProxyToken(
		iframe: ElementRef<HTMLIFrameElement>,
		value: string,
	): void {
		if (!isNil(iframe.nativeElement.contentWindow))
			iframe.nativeElement.contentWindow.postMessage(
				{
					proxyToken: value,
					postMsgResponseRequired: true,
				},
				this.configService.configValues.fnoPortalBaseUrl,
			);
		else
			throw this.classicPortalIframeError;
	}

	public getPortalProxyToken(
		iframe: ElementRef<HTMLIFrameElement>,
	): Observable<IAuthResponse> {
		return this.http.post<IAuthResponse>(
			ProxyApi.proxy,
			null,
			{ params: new ParamMetaData({ authToken: AuthType.USER }) },
		).pipe(
			tap((res) => {
				if (!res.auth_token)
					throw new Error('No authentication token returned');
				else
					this.setPortalProxyToken(iframe, res.auth_token);
			}),
			catchError((error) => {
				return throwError(error);
			}),
		);
	}

	public initiatePortalLogOut(iframe: ElementRef<HTMLIFrameElement>): void {
		if (!isNil(iframe.nativeElement.contentWindow)) {
			const targetOrigin = this.configService.configValues?.fnoPortalBaseUrl;
			iframe.nativeElement.contentWindow.postMessage(
				this.cookieAuthLogoffMsg,
				targetOrigin,
			);
		}
		else
			throw this.classicPortalIframeError;
	}
}
