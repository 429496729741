<app-auth-wrapper [title]="title">
	<form #resetPasswordForm="ngForm" [mustMatch]="['newPassword', 'confirmPassword']" class="k-form k-form-md">
		<fieldset class="k-form-fieldset">
			<kendo-formfield>
				<kendo-label class="field-label" text="Create Password"></kendo-label>
				<kendo-textbox
					#newPasswordInput="ngModel"
					[(ngModel)]="newPassword"
					[clearButton]="true"
					[ngClass]="{ 'is-invalid': newPasswordInput.invalid && (newPasswordInput.dirty || newPasswordInput.touched) }"
					class="custom-input"
					minlength="8"
					name="newPassword"
					appPasswordValidator
					required
				></kendo-textbox>
				<div *ngIf="newPasswordInput.invalid && newPasswordInput.touched">
					<kendo-formerror *ngIf="newPasswordInput.errors?.minlength">Password must be at least 8 characters</kendo-formerror>
					<kendo-formerror *ngIf="newPasswordInput.errors?.lowercase">At least one lower case character</kendo-formerror>
					<kendo-formerror *ngIf="newPasswordInput.errors?.uppercase">At least one upper case character</kendo-formerror>
					<kendo-formerror *ngIf="newPasswordInput.errors?.specialChar">At least one special character></kendo-formerror>
				</div>
			</kendo-formfield>
			<kendo-formfield>
				<kendo-label class="field-label" text="Confirm Password"></kendo-label>
				<kendo-textbox
					#confirmPasswordInput="ngModel"
					[(ngModel)]="confirmPassword"
					[clearButton]="true"
					[ngClass]="{ 'is-invalid': confirmPasswordInput.invalid && (confirmPasswordInput.dirty || confirmPasswordInput.touched) }"
					class="custom-input"
					name="confirmPassword"
					required
				></kendo-textbox>
				<div *ngIf="confirmPasswordInput.invalid && confirmPasswordInput.touched">
					<kendo-formerror *ngIf="confirmPasswordInput.errors['mustMatch']">Passwords must match</kendo-formerror>
				</div>
			</kendo-formfield>
			<button (click)="resetPassword()" [disabled]="resetPasswordForm.invalid" class="auth-form-button" kendoButton themeColor="primary">Submit</button>
		</fieldset>
	</form>
</app-auth-wrapper>
