export const INTERNAL_DOMAIN = '.aex.rocks';
export const EXTERNAL_DOMAIN = '.aex.systems';

export const KEY_AUTH_TOKEN = 'auth-token';
export const HEADER_AUTH = 'Authorization';
export const PROVIDER = 'provider_id';
export const ENTITY = 'entity';
export const IS_ISP_ADMIN = 'is_isp_admin';
export const COUNTRY_CODE = 'country_code';
export const KEY_PROVIDER_TOKEN = 'provider-token';
export const OPERATOR_ID_CLAIM = 'operator_id';
export const USER_ID_CLAIM = 'user_id';
export const ROLE_CLAIM_PREFIX = 'role:';

export const NO_RESPONSE_STATUS = 0;

export const HEADER_LOCATION = 'Location';
export const KEY_CURRENT_USER = 'current-user';
export const KEY_USER_ID = 'user-id';

export const DEFAULT_CONFIG = 'default';

export const DEFAULT_PAY_NOW_TEXT = 'Pay Now';
