import { Component } from '@angular/core';
import { generalFaqV1Data, generalFaqV2Data, portal2FaqV1Data, portal2FaqV2Data } from '../../util/mock-data';
import { FAQ } from '@aex/shared/common-lib';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
	public readonly portalFaqsV1: FAQ[] = portal2FaqV1Data;
	public readonly portalFaqsV2: FAQ[] = portal2FaqV2Data;
	public readonly generalFaqV1: FAQ[] = generalFaqV1Data;
	public readonly generalFaqV2: FAQ[] = generalFaqV2Data;
}
