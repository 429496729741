<div class="auth-screen">
	<div class="auth-wrapper">
		<div class="auth-header">
			<div class="auth-logo"></div>
			<div class="auth-title">
				<p>{{ title }}</p>
			</div>
		</div>
		<ng-content></ng-content>
	</div>
	<!-- Footer -->
	<div class="footer-container">
		<p class="footer-text">{{ footerText }}</p>
	</div>
</div>
