import {Injectable} from '@angular/core';
import {
  Company,
  FilterTypeIds,
  ICreateOrUpdateWorkOrder,
  IInstall,
  IInstallBase,
  InitialScheduler,
  ISchedulerWorkOrder,
  ITokenResult,
  SaveAndSetCalendarEvent,
  SchedulerOrder,
  Teams,
  WorkOrderService,
  InstallLoadType,
} from '@aex/shared/common-lib';
import {BehaviorSubject, forkJoin, Observable, zip} from 'rxjs';
import {addHours, format} from 'date-fns';
import {HttpClient} from '@angular/common/http';
import {EventsService, WorkOrderApi} from '@aex/shared/apis';
import {map} from 'rxjs/operators';
import {CalendarEventAction} from 'angular-calendar';
import {IPagedResponse} from '@aex/ngx-toolbox';

@Injectable({ providedIn: 'root' })
export class SchedulerService {
  public tempTeamsStorage = new BehaviorSubject<Teams[]>(null);
  constructor( private readonly http: HttpClient, private readonly eventsService:EventsService) { }

  // API calls
  public callWorkOrder(statusId: string, startDate: string='', endDate: string='', count: number = 500, installerId?:string ):  Observable<IInstall[]>{
    const tempBody = startDate && endDate ? {
      count,
      start_date: startDate ? SchedulerMain.formatDate(startDate) : null,
      end_date: endDate? SchedulerMain.formatDate(endDate): null,
      installer_id: installerId,
      work_order_status_id: statusId }: {  count, work_order_status_id: statusId };
    return this.http.post<WorkOrderService>(WorkOrderApi.workOrdersSearch,tempBody).pipe(
      map(value => value.items),
    );
  }
  public getPeople(id: string, status: string, company: string): Observable<IInstallBase[]> {
    return this.http.get<IInstallBase[]>(WorkOrderApi.main(id, status, company));
  }
  public getStartUpSchedulerData(): Observable<InitialScheduler> {
    return zip(
      this.http.get<IPagedResponse<IInstall>>(WorkOrderApi.types()),
      this.http.get<IPagedResponse<IInstall>>(WorkOrderApi.statuses()),
      this.http.get<IPagedResponse<IInstall>>(WorkOrderApi.providers()),
      this.http.get<IPagedResponse<IInstall>>(WorkOrderApi.installers()),
    ).pipe(
      map(([
              type,
              statuses,
              providers,
              installers,
           ]) => {
        return ({
          statusesWithNoFilter: statuses.items,
          statuses: statuses.items.filter((item) => item.type_id === FilterTypeIds.PreOrder
            || item.type_id === FilterTypeIds.Installations ||item.type_id === FilterTypeIds.Repairs),
          types: type.items.filter((item) => item.id === FilterTypeIds.PreOrder
            || item.id === FilterTypeIds.Installations ||item.id === FilterTypeIds.Repairs),
          providers: providers.items,
          installers: installers.items,
        });
      }));
  }

  public getInstalls(statuses: IInstallBase [], installLoadType: InstallLoadType, startDate: string='',
                     endDate: string='', teams?:Teams[], installerId?: string): Observable<SchedulerOrder> {
    const response: Observable<IInstall[]>[] = [];
    statuses?.forEach(element=>{
      response.push(this.callWorkOrder(`${element.id}`, startDate, endDate,500, installerId));
    });
    return zip( forkJoin(response) ).pipe(
      map(([ data ]) => {
        const tempData:IInstall[] = [];
        data.forEach((item:IInstall[]) => {
          item.forEach((e:IInstall) => {
            tempData.push(e);
          });
        });
        this.teams = [];
        if(installLoadType === InstallLoadType.Initial)
          this.teams = this.setTeamsStorage(tempData.filter(x=> x.workorder.schedule_date !== null));
         else
          this.teams = teams;
        const tempCalendarEvents = this.getEvents(tempData.filter(x=> x.workorder.schedule_date !== null));
        return ({
          originalData: tempData,
          teams: this.teams,
          calenderEventsScheduled: tempCalendarEvents,
          calendarOriginalEvents: tempCalendarEvents,
          unScheduledWorkOrders: this.getEvents(tempData.filter(x=> x.workorder.schedule_date === null)),
        });

      }));
  }

  public statusTransitions(statusId: string) : Observable<IInstallBase[]>{
    return this.http.get<IPagedResponse<IInstallBase>>(`${WorkOrderApi.workOrderStatusTransitions}${statusId}`).pipe(
      map(value => value.items),
    );
  }
  public getCompanies(id: string, status: string): Observable<[Company[]]> {
    return this.http.get<[Company[]]>(WorkOrderApi.proxyWorkOrdersByStatus(id, status));
  }
  public save(body: ICreateOrUpdateWorkOrder): Observable<IInstall> {
    return this.http.put<IInstall>(WorkOrderApi.workOrder(body.workOrder.toUpperCase() ), {
      work_order:  {
        schedule_time: body.scheduleTime,
        schedule_date: body.scheduleDate,
        status_id: body.statusId,
        assigned_id: body.selectedCompany,
        assigned_user_id: body.selectedPerson === 'Unassigned' ? null : body.selectedPerson,
        comments: body?.comments,
      },
    });
  }
  public setStartDate(date: string, time: string) : Date {
    const startDateSplit = date?.split('T');
    const splitStartTimeZone = startDateSplit?.length > 0 ?  startDateSplit[1]?.split('+') : [];
    const endTimeSplit = time ? time?.split('T') : [];
    const splitEndTime = endTimeSplit?.length>0 ? endTimeSplit[1]?.split('+') :  [];

    return time && date? new Date(`${startDateSplit[0]}T${splitEndTime[0]}+${splitStartTimeZone[1]}`): null;

  }
  public actions: CalendarEventAction[];
  public teams:Teams[] = [];
  // Setting Data and handling data
  public sortTeams(data: IInstall[]): IInstall[] {
    return data.sort((a, b) => (a.name < b.name ? -1 : 1));
  }
  public setEvent(event:IInstall , save:boolean = false, saveData: SaveAndSetCalendarEvent = null): ISchedulerWorkOrder {
    this.teams = this.tempTeamsStorage.getValue();
    if(!save){

      const startTime = this.setStartDate(event?.workorder?.schedule_date,event?.workorder?.schedule_time);
      return {
        actions: this.actions,
        cssClass: this.teams?.find((res: Teams) => res.key === `${event.workorder.assigned_id}`?.toUpperCase())?.style,
        color: this.teams?.find((res: Teams) => res.key ===`${event.workorder.assigned_id}`?.toUpperCase())?.colours,
        customer: {
          address: event?.premise.full_address,
          mapUrl: this.getMapUrl(event?.premise.full_address),
          name: `${event?.customer?.first_name} ${event?.customer?.last_name}`,
          email: event?.customer?.email,
          number: event?.customer?.mobile_number,
        },
        detail: {
          typeId: event?.workorder?.type_id,
          workOrderId: `${event?.workorder?.id}`.toUpperCase(),
          companyId: event?.workorder?.assigned_id?.toUpperCase(),
          userId: event?.workorder?.assigned_user_id?.toUpperCase(),
          user: event?.workorder?.assigned_user_name,
          statusId: event?.workorder?.status_id?.toUpperCase(),
          status: event?.workorder?.status,
          company: event?.workorder?.assigned_to_name,
        },
        event: {
          colour: this.teams?.find((res: Teams) => res.key ===`${event.workorder.assigned_id}`?.toUpperCase())?.colours,
          actions: this.actions,
          group: this.teams?.find((res: Teams) => res.key ===`${event.workorder.assigned_id}`?.toUpperCase())?.value,
          subTitle: `<strong>${event?.workorder?.reference}</strong>`,
          end: event.workorder.schedule_date ? SchedulerMain.getEndTime(startTime):null,
          dateRange: `(${startTime})(${SchedulerMain.getEndTime(startTime)})`,
          reference: event?.workorder?.reference,
          company: event?.workorder?.assigned_to_name,
          style: this.teams?.find((res: Teams) => res.key === `${event.provider.id}`?.toUpperCase())?.style,
          displayTitle: `${event.workorder.assigned_user_name ? event.workorder.assigned_user_name : ' Unassigned'}:<strong>${event?.workorder?.assigned_to_name}</strong>`,
        },
        isp: {
          name: event.isp_product.name,
          premise: event.premise.name,
          product: event.network_product.name,
          id: `${event.isp_product.id}`.toUpperCase(),
        },
        provider: {
          name: event?.provider?.name,
          id: `${event?.provider?.id}`.toUpperCase(),
          icon: `assets/img/isp/${event?.provider?.id.toString().toUpperCase()}.jpg`,
        },
        date: `${startTime}`,
        start: startTime ? startTime : new Date(),
        end: SchedulerMain.getEndTime(startTime),
        title: event?.workorder?.type_id > 1 ? 'Repair' : 'Install',
        draggable: true,
        resizable: { beforeStart: true, afterEnd: false},
        meta: {tmpEvent: false},
      };
    } else{
      const startTime = this.setStartDate(saveData.data?.schedule_date,saveData.data?.schedule_time);
      const localTemp = saveData.workOrder.calendarItem;
      return  {
        actions: null,
        cssClass: this.teams?.find((res: Teams) => res.key === `${saveData.workOrder.calendarItem.detail.companyId}`?.toUpperCase())?.style,
        color: this.teams?.find((res: Teams) => res.key ===`${saveData.workOrder.calendarItem.detail.companyId}`?.toUpperCase())?.colours,
        customer: {
          address: localTemp.customer.address,
          mapUrl: this.getMapUrl(localTemp.customer.address),
          name: `${localTemp.customer.name}`,
          email: localTemp.customer?.email,
          number: localTemp.customer?.number,
        },
        detail: localTemp.detail,
        event: {
          colour: localTemp.event.colour,
          actions: localTemp.event.actions,
          group: localTemp.event.group,
          subTitle: localTemp.event.subTitle,
          end:  SchedulerMain.getEndTime(startTime),
          dateRange: `(${startTime})(${SchedulerMain.getEndTime(startTime)})`,
          reference: localTemp.event.reference,
          style: localTemp.event.style,
          company: localTemp.detail.company,

          displayTitle:  localTemp.event.displayTitle,
        },
        isp: localTemp.isp,
        provider: localTemp.provider,
        date: `${startTime}`,
        start: startTime ? startTime : new Date(),
        end: SchedulerMain.getEndTime(startTime),
        title: localTemp.title,
        draggable: true,
        resizable: { beforeStart: true, afterEnd: false},
        meta: { tmpEvent: false },
      };
    }

  }
  public setTeamsStorage(teams:IInstall[]) : Teams[]{
    if (this.tempTeamsStorage.getValue()!==null)
      return this.tempTeamsStorage.getValue();
    else {
      this.tempTeamsStorage.next(this.getTeams(teams));
      return this.getTeams(teams);
    }
  }

  public getMapUrl(address: string): string {
    let apiKey: string = '';
    this.eventsService.configStream.subscribe(x=>{
      apiKey = x.googleApiKey;
    });
    const addressUrl = address.toLowerCase().replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '-').replace(/^-+|-+$/g, '');
    const params = new URLSearchParams({
      center: addressUrl,
      zoom: '16',
      size: '615x615',
      maptype: 'roadmap',
      markers: `color:red|${ addressUrl }`,
      key: apiKey,
    }).toString();
    return `https://maps.googleapis.com/maps/api/staticmap?${ params.toString() }`;
  }
  private getTeams(teams: IInstall[]): Teams[] {
    const tempTeams:Teams[] = [];
    teams.forEach(element => {
      if(tempTeams.findIndex(x=>x.value === element.workorder?.assigned_id?.toUpperCase())<0)
        tempTeams.push({
          key: element.workorder?.assigned_id?.toUpperCase() ? element.workorder?.assigned_id?.toUpperCase() : 'Unassigned',
          value: element.workorder?.assigned_id?.toUpperCase(),
          colours: {
            primary: `primary-group${tempTeams.length+1}`,
            secondary: `secondary-group${tempTeams.length+1}`,
          },
          style: `${element.workorder?.assigned_to_name}`,
        });
    });
    return tempTeams;
  }
  public getEvents(item: IInstall[]): ISchedulerWorkOrder[] {
    const data = item.reduce((unique, o) => {
      if(!unique.some((obj:IInstall) => obj.workorder.id === o.workorder.id))  unique.push(o);
      return unique;
      },[]);
    return data?.map((event:IInstall) => {
      return this.setEvent(event);
    });
  }

}

export class SchedulerMain{
  private static readonly dateFormat = 'yyyy-MM-dd';
  private static readonly timeFormat = 'HH:mm:ss';
  public static getStartTime(date: string, time: string): Date {
    const newDate = new Date(date);
    const endDate = new Date(time);
    return new Date(newDate?.getFullYear(),newDate?.getMonth(), newDate?.getDate(),
      endDate?.getHours(), endDate?.getMinutes());
  }

  public static addDays(date: Date, days:number) {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    date.setDate(days + date.getDate());
    return date;
  }
  public static getEndTime(dTime: Date, duration: number = 2): Date {
    if (this.isEmpty(dTime))
      return null;
    return addHours(dTime, duration);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static exists(array: any[], value: any): any {
    return array.findIndex(i => i === value) !== -1;
  }
  public static formatDate(date: string): string {
    if (this.isEmpty(date)) return null;
    return format(new Date(date), this.dateFormat);
  }

  public static formatTime(time: string): string {
    if (this.isEmpty(time)) return null;
    return format(new Date(time), this.timeFormat);
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static isEmpty(value: any): boolean {
    return value === null || value === undefined || value === '';
  }
  public static checkIfDateIsGreaterThenToday(date: string): boolean {
    return new Date(date) > new Date();
  }
  public static checkIfUserIs(userRole: string, tokenResult: ITokenResult): boolean {
    return tokenResult.claims.find((role: string) => role === userRole)  === userRole;
  }
  public static capitalizeFirstLetter(str: string): string {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  }
  public static checkDateRange(startDate: Date, endDate: Date, checkingDate: Date) : boolean{
    return checkingDate >= startDate && checkingDate <= endDate;
  }
}