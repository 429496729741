<div class="header-container" data-testid="header-container">
	<div class="server-title" data-testid="top-bar" [ngClass]="[serverEnvBackgroundClass]">
		{{ server }}
	</div>
	<div class="main-header" data-testid="main-header">
		<div class="inner-container">
			<div class="header-operator-logo"></div>
			<div class="input-container">
				<img [height]="imageHeight" [ngSrc]="searchIcon" [width]="imageWidth" alt="search-icon" />
				<kendo-autocomplete
					(afterValueChanged)="onAfterValueChange($event)"
					[data]="globalSearchList"
					[value]="searchQuery"
					[filterable]="true"
					[placeholder]="placeholder"
					class="global-search"
					data-testid="header-search-input"
				>
					<ng-template kendoAutoCompleteItemTemplate let-dataItem>
						<div class="template-container">
							<div class="heading">{{ dataItem.heading }}</div>
							<div (click)="navigateTo(item)" *ngFor="let item of dataItem.data" class="global-search-items">
								<div>{{ item.primaryVal }}</div>
								<div class="val">{{ item.secondaryVal }}</div>
							</div>
						</div>
					</ng-template>
				</kendo-autocomplete>
			</div>
			<div class="menu-container">
				<div kendoTooltip position="top">
					<img
						(click)="openSupportDropdown()"
						[height]="imageHeight"
						[ngSrc]="supportIcon"
						[width]="imageWidth"
						[title]="'Need help?'"
						alt="support-button"
						class="w-auto cursor-pointer"
						data-testid="support-button"
					/>
				</div>
				<div class="border-l h-4"></div>
				<button (click)="openProfileDropdown()" data-testid="profile-button" id="profile-button">
					<img [height]="profileImageHeight" [ngSrc]="profileIcon" [width]="profileImageWidth" alt="profile-icon" />
					{{ fno | titlecase }}
				</button>
			</div>
		</div>
		<img *ngIf="manageProfile" [height]="polygonImageHeight" [ngSrc]="polygonIcon" [width]="polygonImageWidth" alt="polygon-icon" class="profile-img polygon-icon" />
		<app-user-menu *ngIf="manageProfile" data-testid="header-user-menu" id="header-user-menu"></app-user-menu>
		<img *ngIf="helpAndSupport" [height]="polygonImageHeight" [ngSrc]="polygonIcon" [width]="polygonImageWidth" alt="polygon-icon" class="help-n-supp-img polygon-icon" />
		<app-help-n-support *ngIf="helpAndSupport" id="help-n-support"></app-help-n-support>
	</div>
</div>
