import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-auth-wrapper',
	templateUrl: './auth-wrapper.component.html',
	styleUrls: ['./auth-wrapper.component.scss'],
})
export class AuthWrapperComponent {
	@Input() public title: string;
	public footerText: string = `Integrated Network Management System© Automation Exchange ${new Date().getFullYear()} | All rights reserved.`;

}
