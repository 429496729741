import { ICustomFilterExpression } from '@aex/shared/common-lib';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
	selector: 'app-listing-filters',
	templateUrl: './listing-filters.component.html',
})
export class ListingFiltersComponent {
	@Input() public anchor: ElementRef;
	@Input() public opened: boolean;
	@Input() public filters: ICustomFilterExpression[];
	@Input() public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	@Output() public readonly applyFilter = new EventEmitter<CompositeFilterDescriptor>();
	@Output() public readonly toggle = new EventEmitter<boolean>();
	@ViewChild('popup', { read: ElementRef }) public popup: ElementRef;


	public handleApplyFilter(value: CompositeFilterDescriptor): void {
		this.toggle.emit(false);
		this.applyFilter.emit(value);
	}

}
