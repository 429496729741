import { Component, Input } from '@angular/core';
import { FAQ } from '@aex/shared/common-lib';

@Component({
	selector: 'app-faq-panel-content',
	template: `
		<div *ngFor="let detail of item.details">
			<ng-container *ngIf="!detail.list">
				<p *ngFor="let text of detail.content">{{ text }}</p>
			</ng-container>

			<ng-container *ngIf="detail.list">
				<ul *ngIf="detail.listType === 'ul'">
					<li *ngFor="let text of detail.content">{{ text }}</li>
				</ul>
				<ol *ngIf="detail.listType === 'ol'">
					<li *ngFor="let text of detail.content">{{ text }}</li>
				</ol>
			</ng-container>
		</div>
	`,
})
export class FAQPanelContentComponent {
	@Input() public item: FAQ;
}
