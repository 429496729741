import { getMetaData, PORTAL } from '@aex/shared/common-lib';
import { HttpErrorService } from '@aex/shared/root-services';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PortalErrorInterceptor implements HttpInterceptor {
	constructor(private readonly router: Router, private readonly httpError: HttpErrorService) {
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				const meta = getMetaData(request.params);
				this.httpError.handleError(error, meta && meta.handleError);
				if (error?.status === HttpStatusCode.Unauthorized)
					this.router.navigate([PORTAL.loginPage.name]).then();

				return throwError(error);
			}),
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public handleUnauthorizedRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Clone request and update if needed
		const updatedRequest = request.clone();
		return next.handle(updatedRequest);
	}
}
