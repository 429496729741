import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IConfig } from '@aex/shared/common-lib';

@Injectable({ providedIn: 'root' })
export class EventsService {
  public configs = new BehaviorSubject<IConfig>(null);
  public configStream = this.configs.asObservable();

  setConfig(value: IConfig){
    this.configs.next(value);
  }
}