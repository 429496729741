import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-grid-view-button',
	templateUrl: './grid-view-button.component.html',
})
export class GridViewButtonComponent {
	@ViewChild('gridViewButton') public gridViewButton: ElementRef;
	@Input() public view: string;
	@Input() public selected: boolean = false;
	@Input() public removable?: boolean = true;

	@Output() public readonly handleClick = new EventEmitter<string>();
	@Output() public readonly handleDelete = new EventEmitter<string>();

	public imageWidth: string = '14';
	public imageHeight: string = '14';

	public readonly crossIcon: string = 'assets/img/portal/cross.svg';
	public readonly trashIcon: string = 'assets/img/portal/trash.svg';
	public flagged: boolean = false;

	public applyView(): void {
		this.handleClick.emit(this.view);
	}

	public showConfirm(event: Event): void {
		event.stopPropagation();
		this.flagged = true;
	}

	public confirmDelete(event: Event): void {
		event.stopPropagation();
		this.handleDelete.emit(this.view);
		this.flagged = false;
	}

	@HostListener('document:click', ['$event'])
	public documentClick(event: MouseEvent): void {
		if (!this.flagged)
			return;

		if (!this.contains(event.target as HTMLElement))
			this.flagged = false;
	}

	private contains(target: HTMLElement): boolean {
		return this.gridViewButton.nativeElement.contains(target);
	}
}

