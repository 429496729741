export const GREEN_CHIP_COLOR = 'bg-common-green-v1';
export const AMBER_CHIP_COLOR = 'bg-common-amber';
export const RED_CHIP_COLOR = 'bg-alerts-danger-text-v1';

export const PAGE_SIZE_LEVEL_1 = 5;
export const PAGE_SIZE_LEVEL_2 = 10;
export const PAGE_SIZE_LEVEL_3 = 15;
export const PAGE_SIZE_LEVEL_4 = 20;
export const COPY_ICON = 'assets/img/portal/copy.svg';

export const PRE_ORDER_KEY = '4096';
export const INSTALLATION_KEY = '1';
export const L3_CONFIG_KEY = '2048';
export const PROVISIONING_KEY = '2';
export const ACTIVATION_KEY = '128';

export const CHANGE_SERVICE_KEY = '4';

export const CANCELLATION_KEY = '8';
export const DEPROVISIONING_KEY = '64';
export const DEVICE_RECOVERY_KEY = '32';

export const REPAIR_KEY = '1024';
export const FAULT_KEY = '512';

export const svgImageWidth = '15';
export const svgImageHeight = '15';
export const arrowSvgImageWidth = '10';
export const arrowSvgImageHeight = '7';
export const profileSvgImageWidth = '12';
export const profileSvgImageHeight = '14';
export const polygonSvgImageWidth = '12';
export const polygonSvgImageHeight = '6';
export const NEW_ORDER_TABS_KEYS = [PRE_ORDER_KEY, INSTALLATION_KEY, L3_CONFIG_KEY, PROVISIONING_KEY, ACTIVATION_KEY];
export const CHANGES_TABS_KEYS = [CHANGE_SERVICE_KEY];
export const CANCELLATIONS_TABS_KEYS = [CANCELLATION_KEY, DEPROVISIONING_KEY, DEVICE_RECOVERY_KEY];
export const SUPPORT_TABS_KEYS = [REPAIR_KEY, FAULT_KEY];

export const CUSTOMER_COLUMN = 'customer';
export const SERVICE_COLUMN = 'service';
export const WORKORDER_COLUMN = 'workorder';
export const REFERENCE_COLUMN = 'reference';
export const PREMISE_COLUMN = 'premise';
export const ASSET_REFERENCE_COLUMN = 'asset_reference';
export const DEVICE_ASSET_COLUMN = 'device_asset_attributes';

export const DEVICE_STATUS_COLUMN = 'device_asset_attributes.status';
export const AREA_STATUS_COLUMN = 'area.area_status';
export const SERVICE_STATUS_COLUMN = 'service.status';

export const TERMINATION_STATUSES = [
	'closed',
	'abandoned',
	'rejected',
	'complete',
	'provisioned',
	'service changed',
	'cancelled',
	'device recovered',
	'deprovisioned',
	'approved',
	'done',
	'configured',
];

export const BLOCKED_STATUSES = ['failed']

export const CHIP_STATUS_FIELDS = [DEVICE_STATUS_COLUMN, AREA_STATUS_COLUMN, SERVICE_STATUS_COLUMN];

export const SERVICE_STATUSES = {
	CONNECTED: 'Connected',
	ACTIVE: 'Active',
	PENDING_ISP_APPLICATION: 'Pending ISP Application',
	EXPRESSION_OF_INTEREST: 'Expression of Interest',
	CANCELLATION_IN_PROGRESS: 'Cancellation in Progress',
	ISP_CHANGED: 'ISP Changed',
	ISP_CHANGE_PENDING : 'ISP Change Pending',
	PRODUCT_CHANGED: 'Product Changed',
	PRODUCT_CHANGE_PENDING: 'Product Change Pending',
	ORDER: 'Order',
	SUSPENDED: 'Suspended',
	OSP: 'OSP',
	ISP: 'ISP',
	PROVISIONING: 'Provisioning',
	EXPIRED: 'Expired',
	CANCELLED: 'Cancelled',
	REJECTED: 'Rejected',
	DELETED: 'Deleted',
} as const;

export const SERVICE_CHIP_COLOR_STATUS = {
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.CONNECTED}`]: GREEN_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.ACTIVE}`]: GREEN_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.PENDING_ISP_APPLICATION}`]: AMBER_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.EXPRESSION_OF_INTEREST}`]: GREEN_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.CANCELLATION_IN_PROGRESS}`]: GREEN_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.ISP_CHANGED}`]: GREEN_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.PRODUCT_CHANGED}`]: GREEN_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.PRODUCT_CHANGE_PENDING}`]: GREEN_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.ORDER}`]: AMBER_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.SUSPENDED}`]: AMBER_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.OSP}`]: AMBER_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.ISP}`]: AMBER_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.PROVISIONING}`]: AMBER_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.EXPIRED}`]: RED_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.CANCELLED}`]: RED_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.REJECTED}`]: RED_CHIP_COLOR,
	[`${SERVICE_STATUS_COLUMN}-${SERVICE_STATUSES.DELETED}`]: RED_CHIP_COLOR,

	[`${DEVICE_STATUS_COLUMN}-Up`]: GREEN_CHIP_COLOR,
	[`${DEVICE_STATUS_COLUMN}-Down`]: RED_CHIP_COLOR,

	[`${AREA_STATUS_COLUMN}-Connected`]: GREEN_CHIP_COLOR,
	[`${AREA_STATUS_COLUMN}-Pre Order`]: GREEN_CHIP_COLOR,
	[`${AREA_STATUS_COLUMN}-Show Your Interest`]: GREEN_CHIP_COLOR,
	[`${AREA_STATUS_COLUMN}-On hold`]: AMBER_CHIP_COLOR,
	[`${AREA_STATUS_COLUMN}-Suspended`]: RED_CHIP_COLOR,
	[`${AREA_STATUS_COLUMN}-Cancelled`]: RED_CHIP_COLOR,
};

export const CUSTOMER_DETAIL_CHIP_COLOR_STATUS: { [key: string]: string } = {
	[`service-${SERVICE_STATUSES.CONNECTED}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.ACTIVE}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.PENDING_ISP_APPLICATION}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.EXPRESSION_OF_INTEREST}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.CANCELLATION_IN_PROGRESS}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.ISP_CHANGED}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.PRODUCT_CHANGED}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.PRODUCT_CHANGE_PENDING}`]: GREEN_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.ORDER}`]: AMBER_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.SUSPENDED}`]: AMBER_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.OSP}`]: AMBER_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.ISP}`]: AMBER_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.PROVISIONING}`]: AMBER_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.EXPIRED}`]: RED_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.CANCELLED}`]: RED_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.REJECTED}`]: RED_CHIP_COLOR,
	[`service-${SERVICE_STATUSES.DELETED}`]: RED_CHIP_COLOR,

	['device-up']: GREEN_CHIP_COLOR,
	['device-down']: RED_CHIP_COLOR,

	['payment-NotApplicable']: AMBER_CHIP_COLOR,
	['payment-UpToDate']: GREEN_CHIP_COLOR,
	['payment-InArrears']: RED_CHIP_COLOR,
};

export const CLASSIC_PORTAL_ERROR_NOT_FOUND = 'iframe not found';
export const STATUS_COUNT = 30;
export const PROGRESS_COUNT = 80;
