import { CommonModule, NgOptimizedImageModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PORTAL_APP_ROUTES } from '../../app-routing.module';
import { AuthWrapperComponent } from '../../components/shared/auth-wrapper/auth-wrapper.component';
import { SharedModule } from '../../components/shared/shared.module';
import { KendoModule } from '../../kendo.module';
import { CheckEmailComponent } from './check-email/check-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AexNgToolboxModule } from '@aex/ngx-toolbox';
import { CommonLibModule } from '@aex/shared/common-lib';

@NgModule({
	declarations: [
		LoginComponent,
		AuthWrapperComponent,
		ForgotPasswordComponent,
		CheckEmailComponent,
		ResetPasswordComponent,
	],
	imports: [
		CommonModule,
		...KendoModule,
		FormsModule,
		NgOptimizedImageModule,
		SharedModule,
		RouterModule.forRoot(PORTAL_APP_ROUTES),
		AexNgToolboxModule.forChild(),
		CommonLibModule,
	],
	exports: [
		LoginComponent,
		AuthWrapperComponent,
		ForgotPasswordComponent,
		CheckEmailComponent,
		ResetPasswordComponent,
	],
})
export class AuthModule { }
