import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomatedInsightsComponent } from './automated-insights-report/automated-insights-report.component';
import { CommonLibModule } from '@aex/shared/common-lib';

@NgModule({
	imports: [
		CommonModule,
		CommonLibModule,
	],
	declarations: [
		AutomatedInsightsComponent,
	],
	exports: [
		AutomatedInsightsComponent,
	],
})
export class ReportsModule {}
