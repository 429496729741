import { Component, HostListener } from '@angular/core';
import {
	ASSET_REFERENCE_COLUMN,
	CUSTOMER_COLUMN,
	DEVICE_ASSET_COLUMN,
	polygonSvgImageHeight,
	polygonSvgImageWidth,
	PREMISE_COLUMN,
	profileSvgImageHeight,
	profileSvgImageWidth,
	REFERENCE_COLUMN,
	SERVICE_COLUMN,
	svgImageHeight,
	svgImageWidth,
	WORKORDER_COLUMN,
} from '../../shared/constant';
import { SearchService } from '@aex/shared/apis';
import { Router } from '@angular/router';
import { GlobalSearchObjects, IGlobalSearchItems, IInformationalObjects, MyRecordType, PORTAL } from '@aex/shared/common-lib';
import { IGlobalSearchData, IGlobalSearchObject } from '../../shared/interfaces/interface';
import { ConfigService } from '@aex/shared/root-services';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	public fno: string;
	public server: string;
	public serverEnvBackgroundClass = '';
	public readonly searchIcon: string = 'assets/img/portal/search-grey.svg';
	public readonly supportIcon: string = 'assets/img/portal/help-button.svg';
	public readonly profileIcon: string = 'assets/img/portal/profile-icon.svg';
	public readonly polygonIcon: string = 'assets/img/portal/polygon.svg';
	public placeholder: string = 'Search for a Customer, Premise, Task or Serial Number';
	public manageProfile: boolean = false;
	public helpAndSupport: boolean = false;
	public globalSearchMap: Map<string, IGlobalSearchData> = new Map();
	public readonly imageWidth: string = svgImageWidth;
	public readonly imageHeight: string = svgImageHeight;
	public readonly profileImageWidth: string = profileSvgImageWidth;
	public readonly profileImageHeight: string = profileSvgImageHeight;
	public readonly polygonImageWidth: string = polygonSvgImageWidth;
	public readonly polygonImageHeight: string = polygonSvgImageHeight;
	public searchQuery: string = '';
	public readonly serviceKey: string = SERVICE_COLUMN;
	public readonly customerKey: string = CUSTOMER_COLUMN;
	public readonly workOrderKey: string = WORKORDER_COLUMN;
	public readonly referenceKey: string = REFERENCE_COLUMN;
	public readonly premiseKey: string = PREMISE_COLUMN;
	public readonly assetReferenceKey: string = ASSET_REFERENCE_COLUMN;
	public readonly deviceAssetKey: string = DEVICE_ASSET_COLUMN;

	public get globalSearchList(): IGlobalSearchData[] {
		return Array.from(this.globalSearchMap.values());
	}

	constructor(private readonly searchService: SearchService, private readonly router: Router, private readonly configService: ConfigService) {
		this.initialiseServerDetails();

		this.globalSearchMap.set(this.customerKey, {
			heading: 'Customers',
			key: this.customerKey,
			data: [],
		});

		this.globalSearchMap.set(this.premiseKey, {
			heading: 'Premises',
			key: this.premiseKey,
			data: [],
		});

		this.globalSearchMap.set(this.workOrderKey, {
			heading: 'Tasks',
			key: this.workOrderKey,
			data: [],
		});

		this.globalSearchMap.set(this.deviceAssetKey, {
			heading: 'Serial Numbers',
			key: this.deviceAssetKey,
			data: [],
		});
	}

	private initialiseServerDetails(): void {
		const configName = this.configService.getConfigName();
		this.server = `${configName.toUpperCase()} SERVER`;
		this.fno = this.configService.fnoName;
		this.serverEnvBackgroundClass = configName;
	}

	public onAfterValueChange(value: string): void {
		this.searchQuery = value;
		if (this.searchQuery !== '')
			 this.loadData();
	}

	public loadData(): void {
		this.searchService.globalSearch({ search_string: this.searchQuery }).subscribe((res) => {
			this.createGlobalSearchRecords(res.body.items);
		});
	}

	public openProfileDropdown(): void {
		this.manageProfile = !this.manageProfile;
		this.helpAndSupport = false;
	}

	public openSupportDropdown(): void {
		this.helpAndSupport = !this.helpAndSupport;
		this.manageProfile = false;
	}

	public createGlobalSearchRecords(items: IGlobalSearchItems[]): void {
		let customerRecords: IGlobalSearchObject[] = [];
		let premiseRecords: IGlobalSearchObject[] = [];
		let taskRecords: IGlobalSearchObject[] = [];
		let serialNumberRecords: IGlobalSearchObject[] = [];

		items.map((el) => {
			let { service: serviceObject, premise: premiseObject, customer: customerObject } = this.getRecordObjects(el?.informational_objects);
			const matchedObj = el.matched_objects[0];
			const matchedObjFieldName = matchedObj.field_matches[0].field_name
			const item = {
				title: matchedObj?.object_type,
				primaryVal: matchedObj?.field_matches[0].matches[0].replace(/{{/g, '').replace(/}}/g, ''),
				secondaryVal: customerObject?.full_name as string,
				serviceId: serviceObject?.id as string,
				customerId: customerObject?.id as string,
			};

			if (item.title === this.customerKey) {
				const customerFullName = matchedObj?.field_matches.find((obj) => obj.field_name === `${this.customerKey}.full_name`);
				if (customerFullName)
					item.primaryVal = customerFullName.matches[0].replace(/{{/g, '').replace(/}}/g, '');

				item.secondaryVal = premiseObject?.external_reference as string;
				customerRecords.push(item);
			} else if (item.title === this.workOrderKey && matchedObjFieldName === `${this.workOrderKey}.${this.referenceKey}`)
					taskRecords.push(item);
			 	else if (matchedObjFieldName === `${this.premiseKey}.${this.assetReferenceKey}`)
					serialNumberRecords.push(item);
				else if (item.title === this.premiseKey)
					premiseRecords.push(item);
				else
					serialNumberRecords.push(item);
		});

		const keyToRecordsMapper = {
			[this.customerKey]: customerRecords,
			[this.premiseKey]: premiseRecords,
			[this.workOrderKey]: taskRecords,
			[this.deviceAssetKey]: serialNumberRecords,
		};

		Object.keys(keyToRecordsMapper).forEach((key) => {
			this.globalSearchMap.set(key, { ...this.globalSearchMap.get(key), data: keyToRecordsMapper[key] });
		});
	}

	public navigateTo(value: IGlobalSearchObject): void {
		if (value.serviceId)
			this.router.navigate([PORTAL.customerPage.name, value.serviceId]).then();
		else
			this.searchService.getAllCustomers({ search_string: value.customerId }).subscribe((response) => {
				this.router.navigate([PORTAL.customerPage.name, response.body.items[0].service.id]).then();
			});
		this.searchQuery = '';
	}

	// TODO - this triggers on every button click
	@HostListener('document:click', ['$event'])
	public onClick(event: MouseEvent): void {
		const clickedElement = event.target as HTMLElement;
		const isInsideProfileMenu = clickedElement.closest('[id="header-user-menu"]');
		const isInsideSupportMenu = clickedElement.closest('[id="help-n-support"]');
		const isInsideProfileButton = clickedElement.closest('[data-testid="profile-button"]');
		const isInsideSupportButton = clickedElement.closest('[data-testid="support-button"]');
		const isInsideFNOPopup = clickedElement.closest('[class="k-list-group-item"]');
		const isInsideManageProfileButton = clickedElement.closest('[class="user-menu-container"]');
		const logoutButtonClicked = clickedElement.closest('[class="btn-logout"]');
		const canCloseManageProfile = !isInsideProfileMenu && !isInsideProfileButton && !isInsideFNOPopup;

		if ((!!canCloseManageProfile || !!isInsideManageProfileButton) && !logoutButtonClicked)
			this.manageProfile = false;

		if (!isInsideSupportMenu && !isInsideSupportButton)
			this.helpAndSupport = false;
	}

	private getRecordObjects(informationalObjects?: IInformationalObjects[]): GlobalSearchObjects {
		let serviceObject: MyRecordType;
		let customerObject: MyRecordType;
		let premiseObject: MyRecordType;

		if (informationalObjects && Array.isArray(informationalObjects))
			for (const informationalObject of informationalObjects)
				switch (informationalObject.object_type) {
					case this.serviceKey:
						serviceObject = informationalObject.object;
						break;
					case this.customerKey:
						customerObject = informationalObject.object;
						break;
					case this.premiseKey:
						premiseObject = informationalObject.object;
						break;
					default:
						break;
				}

		return { service: serviceObject, customer: customerObject, premise: premiseObject };
	}
}
