import { AuthService } from '@aex/shared/root-services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class PortalAuthRouteGuard implements CanActivate {
	constructor(private readonly authService: AuthService) {
	}

	public canActivate(
		_next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean {
		if (!this.authService.isLoggedIn) {
			this.authService.gotoLogin(state.url);
			return false;
		}
		return true;
	}
}
