import { WithDestroy } from '@aex/ngx-toolbox';
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
	selector: '[afterValueChanged]',
})
export class AfterValueChangedDirective extends WithDestroy() {
	@Output()
	public readonly afterValueChanged: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	public valueChangeDelay = 300;

	@Input()
	public checkLastValidValue = true;

	private readonly stream: Subject<string> = new Subject<string>();
	private lastValidValue: string = '';

	constructor() {
		super();
		this.noZombie(this.stream.pipe(debounceTime(this.valueChangeDelay))).subscribe((value: string) => {
			const trimmedValue = value.trim();
			if (trimmedValue.length > 0 && (!this.checkLastValidValue || trimmedValue !== this.lastValidValue)) {
				this.lastValidValue = trimmedValue;
				this.afterValueChanged.next(trimmedValue);
			}
		});
	}

	@HostListener('valueChange', ['$event'])
	public onValueChange(value: string): void {
		this.stream.next(value);
	}

	@HostListener('filterChange', ['$event'])
	public onFilterChange(value: string): void {
		this.stream.next(value);
	}
}
