import { PORTAL } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { Component } from '@angular/core';
import { svgImageHeight, svgImageWidth } from '../../../shared/constant';

@Component({
	selector: 'app-help-n-support',
	templateUrl: 'help-n-support.component.html',
	styleUrls: ['help-n-support.component.scss'],
})
export class HelpAndSupportMenuComponent {
	public imageWidth: string = svgImageWidth;
	public imageHeight: string = svgImageHeight;

	public readonly faqLink: string = PORTAL.faq.name;

	constructor(private readonly configService: ConfigService) {}

	public logATicket(): void {
		window.location.href = this.configService.operatorTicketUrl;
	}
}
