import { DeviceApi, WorkOrderApi } from '@aex/shared/apis';
import {
	AuthType,
	Company,
	ICancellationReason,
	IGridColumn,
	IPagedResponseExtended,
	IPagination,
	IWorkOrder,
	IWorkOrderHistory,
	IWorkOrderStatusResponse,
	IWorkOrderUpdate,
	IWorkOrders,
	ParamMetaData,
	WorkOrderType,
	IWorkOrderType,
	IWorkOrderStatus,
	IWorkOrderTransition,
} from '@aex/shared/common-lib';
import { AuthService } from '@aex/shared/root-services';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITasksGridData } from 'libs/shared/common-lib/src/interfaces/task';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPagedResponse } from '@aex/ngx-toolbox';

@Injectable({
	providedIn: 'root',
})
export class WorkOrderService {
	public customerId: string;

	constructor(private readonly http: HttpClient, private readonly authService: AuthService) {}

	public getWorkOrder(workorderId: string): Observable<IWorkOrder> {
		return this.http.get<IWorkOrder>(WorkOrderApi.workOrder(workorderId));
	}

	public getWorkOrders(): Observable<IWorkOrder[]> {
		return this.http.get<IWorkOrder[]>(WorkOrderApi.workOrders);
	}

	public getAllWorkOrders(payload?: unknown): Observable<HttpResponse<IWorkOrders>> {
		let body = {};
		if (payload) body = payload;
		return this.http.post<IWorkOrders>(WorkOrderApi.statusCounter(), body, { observe: 'response' });
	}

	public createWorkOrder(workOrder: IWorkOrderUpdate, sync: boolean = false): Observable<IWorkOrderUpdate> {
		return this.http.post<IWorkOrderUpdate>(WorkOrderApi.createWorkOrder(), workOrder, {
			headers: { Authorization: this.authService.authToken },
			...(sync ? { params: { sync: true } } : {}),
		});
	}

	public updateWorkOrder(workOrderId: string, workOrder: Partial<IWorkOrder>): Observable<IWorkOrder> {
		return this.http.put<IWorkOrder>(
			WorkOrderApi.workOrder(workOrderId),
			{ work_order: workOrder },
			{
				params: new ParamMetaData({ handleError: 'workorders' }),
				headers: { Authorization: this.authService.authToken },
			},
		);
	}

	public getServiceWorkOrder(serviceId: string, type: number = null): Observable<IPagedResponseExtended<IWorkOrder>> {
		const params = new ParamMetaData({ handleError: 'workorders', authToken: AuthType.PROXIED }).set('service', serviceId).withAllCount();
		if (type) params.set('type', type);
		return this.http
			.get<IPagedResponseExtended<IWorkOrder>>(WorkOrderApi.workOrders, {
				params,
			})
			.pipe(
				map((workOrders) => {
					workOrders?.items?.filter((workOrder) => isEmpty(workOrder.close_date) && workOrder.status_id !== WorkOrderType.Cancellation.toString());
					return workOrders;
				}),
			);
	}

	public getServiceWorkOrders(serviceId: string): Observable<IPagedResponseExtended<IWorkOrder>> {
		const params = new ParamMetaData({ handleError: 'workorders', authToken: AuthType.PROXIED }).set('service', serviceId).withAllCount();
		return this.http.get<IPagedResponseExtended<IWorkOrder>>(WorkOrderApi.workOrders, {
			params,
		});
	}

	public getWorkOrderServices(reference: string): Observable<unknown> {
		return this.http.get<unknown>(DeviceApi.referenceStatus(reference), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getQuotePDF(workOrderRef: string): Observable<Blob> {
		return this.http.get(WorkOrderApi.quoteLocation(workOrderRef), {
			responseType: 'blob',
		});
	}

	public getWorkOrderStatuses(workOrderTypeId: number, count: number = 10, enabled?: boolean): Observable<IWorkOrderStatusResponse> {
		return this.http.get<IWorkOrderStatusResponse>(WorkOrderApi.workOrderStatuses(workOrderTypeId, count, enabled), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getWorkOrderCancellationReasons(): Observable<ICancellationReason[]> {
		return this.http.get<ICancellationReason[]>(WorkOrderApi.cancellationReasons(), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getTransitionsForStatus(statusId: string): Observable<IPagedResponse<IWorkOrderTransition>> {
		const url = WorkOrderApi.getWorkOrderStatusTransitions(statusId);
		return this.http.get<IPagedResponse<IWorkOrderTransition>>(url);
	}

	public getWorkOrderHistory(workOrderId: string): Observable<IPagedResponseExtended<IWorkOrderHistory>> {
		return this.http.get<IPagedResponseExtended<IWorkOrderHistory>>(WorkOrderApi.workOrderHistory(workOrderId));
	}

	public getWorkOrderCompanies(workOrderId: string, statusId: string): Observable<Company[]> {
		return this.http.get<Company[]>(WorkOrderApi.companies(workOrderId, statusId), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getWorkOrderCompanyPeoples(workOrderId: string, statusId: string, companyId: string): Observable<Company[]> {
		return this.http.get<Company[]>(WorkOrderApi.companyPeople(workOrderId, statusId, companyId), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getWorkOrderDeviceVerification(fsan: string): Observable<unknown> {
		return this.http.get<unknown>(DeviceApi.fsanVerificationStatus(fsan), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getWorkOrderPorts(reference: string): Observable<unknown> {
		return this.http.get<unknown>(DeviceApi.availablePorts(reference), {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}

	public getAllTasks(pagination: IPagination): Observable<HttpResponse<ITasksGridData>> {
		return this.http.post<ITasksGridData>(WorkOrderApi.workOrdersSearch, pagination, { observe: 'response' });
	}

	public getAllTaskColumns(): Observable<HttpResponse<IGridColumn[]>> {
		return this.http.get<IGridColumn[]>(WorkOrderApi.columns(), { observe: 'response' });
	}

	public exportTaskGridData(selected_grid_columns: IGridColumn[]): Observable<string> {
		return this.http.post(WorkOrderApi.exportCsv(), { selected_grid_columns: selected_grid_columns }, { responseType: 'text' });
	}

	public getAllWorkOrderTypes(): Observable<IPagedResponse<IWorkOrderType>> {
		return this.http.get<IPagedResponse<IWorkOrderType>>(WorkOrderApi.types());
	}

	public getWorkOrderStatus(workOrderStatusId: string): Observable<IWorkOrderStatus> {
		const params = new ParamMetaData({ handleError: 'workorders' });
		return this.http.get<IWorkOrderStatus>(WorkOrderApi.workOrderStatus(workOrderStatusId), { params });
	}

	public getWorkOrderTypeStatuses(workOrderTypeId: number): Observable<IPagedResponse<IWorkOrderStatus>> {
		const params = new ParamMetaData({ handleError: 'workorders' }).set('type', workOrderTypeId);
		return this.http.get<IPagedResponse<IWorkOrderStatus>>(WorkOrderApi.statuses(), { params });
	}

	// will be completed once we get response from network team
	public getDataUsage(fsan: string): Observable<unknown> {
		return this.http.get<unknown>(`${DeviceApi.dataUsage(fsan)}?fromDate=2024/08/20&endDate=2024/09/18`, {
			params: new ParamMetaData({ handleError: 'workorders' }),
			headers: { Authorization: this.authService.authToken },
		});
	}
}
