import { AlertType, IAlertTheme } from '@aex/shared/common-lib';
import { Component, Input } from '@angular/core';
import { SVGIcon } from '@progress/kendo-svg-icons';

const alertDangerIcon = {
	name: 'AlertDangerIcon',
	content: `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
  <path d="M0 14.8213H17.0659L8.53293 0.178223L0 14.8213ZM9.30866 12.5093H7.75721V10.9679H9.30866V12.5093ZM9.30866 9.4265H7.75721V6.34374H9.30866V9.4265Z" fill="#F31700"/>
  </svg>`,
	viewBox: '0 0 18 15',
};

@Component({
	selector: 'app-alert-bar',
	templateUrl: './alert-bar.component.html',
})
export class AlertBarComponent {
	@Input() public containerClass: string = '';
	@Input() public type?: AlertType;
	@Input() public text: string = '';
	@Input() public textClass: string = '';
	@Input() public showIcon: boolean = true;
	private readonly ALERT_TYPE: { [type in AlertType]?: IAlertTheme } = {
		danger: {
			bgColor: 'bg-alerts-danger-bg-v1',
			color: 'text-alerts-danger-text-v1',
			icon: alertDangerIcon,
		},
	};

	public get containerClasses(): string {
		return `${this.ALERT_TYPE[this.type].bgColor} ${this.containerClass}`;
	}

	public get textClasses(): string {
		return `${this.ALERT_TYPE[this.type].color} ${this.textClass}`;
	}

	public get icon(): SVGIcon {
		return this.ALERT_TYPE[this.type].icon;
	}
}
