export enum SavingStates {
	EDIT,
	ADD,
	BOTH,
}

export enum ViewDialogActions {
	SAVE = 'SAVE',
	UPDATE = 'UPDATE',
	DONT_SAVE = 'DONT_SAVE',
}
